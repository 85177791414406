(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.factory:UtilsFactory
   *
   * @description
   *
   */
  angular
    .module('sportoase')
    .factory('UtilsFactory', UtilsFactory);

  function UtilsFactory(
      $state,
      $log,
      $modal,
      $filter
    ) {
    var vm = this;
    vm.confirmationModalInstance = null;
    vm.showingConfirmationModal = false;

    function displayConfirmationModal(title, body, resultHandler) {
      if (vm.showingConfirmationModal) {
        return;
      }

      vm.confirmationModalInstance = $modal.open({
        templateUrl: 'views/confirmation.modal.view.tpl.html',
        controller: 'ConfirmationPromptCtrl',
        controllerAs: 'confirmationPromptCtrl',
        resolve: {
          title: function () {
            return title;
          },
          body: function () {
            return body;
          }
        }
      });

      vm.showingConfirmationModal = true;

      vm.confirmationModalInstance.result.then(function (returnValue) {
        vm.showingConfirmationModal = false;
        resultHandler(returnValue);
      }, function (returnValue) {
        $log.debug('Reason for closing: ' + returnValue);
        vm.showingConfirmationModal = false;
      });
    }

    return {
      reloadState: function () {
        $log.debug('reloading current state');
        $state.go($state.current, {}, {reload: true});
      },
      intervalStringToValuesObject: function (intervalString) {
        var dateString = '',
            timeString = '',
            dateDesignatorIndex = intervalString.indexOf('P'),
            timeDesignatorIndex = intervalString.indexOf('T'),
            result = {};
        // valid interval string starts with P(eriod)
        if (dateDesignatorIndex === 0) {
          // divide the string into date and time portion
          if (timeDesignatorIndex > -1) {
            timeString = intervalString.slice(timeDesignatorIndex);
            dateString = intervalString.slice(0, timeDesignatorIndex);
          } else {
            dateString = intervalString;
          }

          // use regex magic to determine the actual values
          result.days = parseInt(dateString.match(/(\d+)(?:D)/g), 10);
          result.months = parseInt(dateString.match(/(\d+)(?:M)/g), 10);
          result.years = parseInt(dateString.match(/(\d+)(?:Y)/g), 10);

          if (timeString.length) {
            result.hours = parseInt(dateString.match(/(\d+)(?:H)/g), 10);
            result.minutes = parseInt(dateString.match(/(\d+)(?:M)/g), 10);
            result.seconds = parseInt(dateString.match(/(\d+)(?:S)/g), 10);
          }
        }
        return result;
      },
      valuesObjectToIntervalString: function (valuesObject) {
        var result = 'P',
            hasHours = this.isNotEmpty(valuesObject.hours),
            hasMinutes = this.isNotEmpty(valuesObject.minutes),
            hasSeconds = this.isNotEmpty(valuesObject.seconds);

        if (this.isNotEmpty(valuesObject.days)) {
          result += valuesObject.days + 'D';
        }
        if (this.isNotEmpty(valuesObject.months)) {
          result += valuesObject.months + 'M';
        }
        if (this.isNotEmpty(valuesObject.years)) {
          result += valuesObject.years + 'Y';
        }

        if (hasHours || hasMinutes || hasSeconds) {
          result += 'T';

          if (hasHours) {
            result += valuesObject.hours + 'H';
          }
          if (hasMinutes) {
            result += valuesObject.minutes + 'M';
          }
          if (hasSeconds) {
            result += valuesObject.seconds + 'S';
          }
        }
        return result;
      },
      isNotEmpty: function (variable) {
        var notNull = angular.isDefined(variable) && variable !== null;
        if (!notNull) {
          return false;
        }
        if (angular.isString(variable) || angular.isArray(variable)) {
          return variable.length > 0;
        }
        if (angular.isObject(variable) && !angular.isDate(variable)) {
          return Object.keys(variable).length > 0;
        }

        return true;
      },
      promiseLoop: function (collection, loopCallBack) {
        var elementsToProcess = 0;
        return new Promise(function (resolve, reject) {
          if (angular.isArray(collection)) {
            if (collection.length === 0) {
              resolve();
            } else {
              elementsToProcess = collection.length;
              angular.forEach(collection, function (element) {
                Promise.resolve(loopCallBack(element))
                .then(function () {
                  --elementsToProcess;
                  if (elementsToProcess <= 0) {
                    resolve();
                  }
                });
              });
            }
          } else {
            reject('Collection parameter should be an array.');
          }
        });
      },
      showConfirmationModal: function (title, body, handler) {
        return displayConfirmationModal(
          $filter('uconlyfirst')($filter('translate')(title)),
          $filter('uconlyfirst')($filter('translate')(body)),
          handler
        );
      },
      showFormattedConfirmationModal: function (titleFormat, title, bodyFormat, body, handler) {
        return displayConfirmationModal(
          $filter('uconlyfirst')($filter('sprintf')($filter('translate')(titleFormat), $filter('translate')(title))),
          $filter('uconlyfirst')($filter('sprintf')($filter('translate')(bodyFormat), $filter('translate')(body))),
          handler
        );
      }
    };
  }
}());
