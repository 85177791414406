(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:PromocodeFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('PromocodeFactory', PromocodeFactory);

  function PromocodeFactory(Restangular) {
    var promocodeService = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'promocode'});
      })
      .service('promocode');

    // sportoase-backend.loc/api/promocode/79ddac85-dbaa-11ea-b68c-84fdd1ba2d4f
    // /promocode/{promocode_value}/customer/{customer_id}/validate

    return {
      checkPromocode: function (promocodeValue, customerId, productsData) {
        console.log(promocodeValue);
        console.log(customerId);
        return promocodeService.one(promocodeValue).one('customer').one(customerId).one('validate').get({products: productsData});
      },
      updatePromocode: function (promocodeId, object) {
        return promocodeService.one(promocodeId).customPUT(object);
      }
    };
  }
}());
