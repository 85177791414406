(function () {
  'use strict';

  /* @ngdoc object
   * @name auth
   * @description
   *
   */
  angular
    .module('auth', [
      'ui.router',
      'sportoase.config',
      'angular-oauth2',
      'ngCookies'
    ])
    .factory('customOauthInterceptor', customOauthInterceptor)
    .config(function ($httpProvider, OAuthProvider, OAUTH_CONFIG) {
      'ngInject';
      OAuthProvider.configure({
        baseUrl: OAUTH_CONFIG.baseUrl,
        clientId: OAUTH_CONFIG.clientId,
        clientSecret: OAUTH_CONFIG.clientSecret,
        grantPath: OAUTH_CONFIG.grantPath,
        revokePath: OAUTH_CONFIG.revokePath
      });

      $httpProvider.interceptors.push('customOauthInterceptor');
    })
    .run(function ($rootScope, $state, $log, OAuth, UtilsFactory, ToastrNotificationService, $cookies) {
      'ngInject';
      $rootScope.refreshingToken = false;

      // handle oauth errors
      $rootScope.$on('oauth:error', function (event, rejection) {
        // If the bypass flag is set, ignore oauth errors
        if (event.targetScope && angular.isDefined(event.targetScope.ignoreAuthenticationErrors) &&
          event.targetScope.ignoreAuthenticationErrors) {
          $log.debug('AUTH: Bypassing authentication errors check due to flag');
          return;
        }

        $log.debug('oauth error!');
        $log.debug(rejection.data);
        if (rejection.data.error === 'invalid_token') {
          $log.debug('invalid token -> refreshing!');
          $rootScope.$emit('refreshToken');
        }
        if (rejection.data.error === 'invalid_grant') {
          $log.debug('invalid grant!');
          $rootScope.$broadcast('redirectToLogin', $state.current);
          return;
        }
      });

      // redirect to login page when appropriate
      $rootScope.$on('checkUserCredentials', function (event, next) {
        // If this is an anonymous call, don't redirect to login page
        if (event.targetScope && angular.isDefined(event.targetScope.anonymous) && event.targetScope.anonymous) {
          $log.debug('AUTH: Bypassing credentials check due to anonymous call');
          return;
        }

        // event.stopPropagation();
        // second check prevents refresh attempts before we have a refresh token
        if (!OAuth.isAuthenticated() && (angular.isUndefined($cookies.get('token')) || !OAuth.getRefreshToken())) {
          $log.debug('user is not authenticated and token is not refreshed');
          $rootScope.$emit('redirectToLogin', next);
        }
      });

      // redirect to login page when appropriate
      $rootScope.$on('$viewContentLoaded', function (event, next) {
        $rootScope.$emit('checkUserCredentials', next);
      });

      // refresh the token
      $rootScope.$on('refreshToken', function () {
        if (angular.isUndefined($rootScope.refreshingToken) || !$rootScope.refreshingToken) {
          $rootScope.refreshingToken = true;
          $log.debug('Refreshing oauth token');
          OAuth.getRefreshToken().then(function () {
            $rootScope.refreshingToken = false;
          }, function (errorMsg) {
            $log.debug('could not refresh token! -> ' + errorMsg);
            $rootScope.refreshingToken = false;
          });
        } else {
          $log.debug('token refresh lock already set!');
        }
      });

      // redirect to login page when appropriate
      $rootScope.$on('redirectToLogin', function (event, next) {
        if (angular.isUndefined(next)) {
          next = $state.current;
        }

        if ($state.current.name !== 'kiosk' && next.name !== 'kiosk') {
          $log.debug('redirecting to login page');
          $log.debug($state.current.name);
          $log.debug(next.name);
          // preserve page the user was going to
          $rootScope.returnToState = next.name;
          event.preventDefault();
          $state.go('kiosk.login');
        }
      });
    });

  customOauthInterceptor.$inject = ['$q', '$cookies', '$rootScope', '$log', '$timeout', '$injector', 'OAuthToken', '_'];
  function customOauthInterceptor($q, $cookies, $rootScope, $log, $timeout, $injector, OAuthToken, _) {
    return {
      request: function (config) {
        if (OAuthToken.getAuthorizationHeader()) {
          config.headers = config.headers || {};
          config.headers.Authorization = OAuthToken.getAuthorizationHeader();
        }
        return config;
      },
      responseError: function (rejection) {
        if (_.indexOf([401, 403], rejection.status) > -1) {
          if (angular.isDefined($rootScope.anonymous) && ($rootScope.anonymous === true)) {
            $log.warn('AUTH: Response authentication error, but ignored during anonymous call');
            return null;
          }
        }

        // the angular oauth 2 module expects a different rejection format than the one
        // we receive from the symfony backend.
        // This custom oauth interceptor is meant to solve that problem.
        if (rejection.status === 401 && (rejection.data && rejection.data.error === 'invalid_grant') ||
          rejection.headers('www-authenticate') && rejection.headers('www-authenticate').indexOf('Bearer') === 0) {
          $log.debug('custom oauth request intercept! (401)');
          // no refresh possible - emit error
          if (angular.isUndefined($cookies.get('token'))) {
            $log.debug('no token present, emitting error');
            $rootScope.$emit('oauth:error', rejection);
          } else {
            $log.debug('attempting to refresh token');
            $rootScope.$emit('refreshToken');
            return $timeout(function () {
              var $http = $injector.get('$http');
              return $http(rejection.config);
            }, 2000);
          }
        } else if (rejection.status === 403) {
          $log.debug('forbidden - redirect to login');
          $rootScope.$emit('redirectToLogin');
        } else if (
          rejection.data.hasOwnProperty('errors') && rejection.data.errors.hasOwnProperty('children') &&
          $injector.get('UtilsFactory').isNotEmpty(rejection.data.errors.children) &&
          $injector.get('UtilsFactory').isNotEmpty(rejection.config.headers['x-entity'])) {
          $log.debug('Specific form error data found - sending broadcast');
          $rootScope.$broadcast('showFormErrorMsgBroadcast', rejection);
        } else {
          $rootScope.$broadcast('showErrorMsgToastrBroadcast', rejection);
        }

        return $q.reject(rejection);
      }
    };
  }
}());
