(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.controller:KioskCtrl
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .controller('KioskCtrl', KioskCtrl);

  function KioskCtrl(
    $q,
    $cookies,
    $filter,
    $localStorage,
    $location,
    $log,
    $modal,
    moment,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $interval,
    $window,
    ENVIRONMENTS,
    CurrentUserContextFactory,
    EndpointFactory,
    EventFactory,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    FitnessFactory,
    FitnessEventParticipationService,
    LocaleService,
    MyEntitiesFactory,
    OAuth,
    OAuthToken,
    ProductFactory,
    PromocodeFactory,
    Restangular,
    RestUtilsFactory,
    SaleFactory,
    SettingsService,
    ShoppingCartService,
    ToastrNotificationService,
    UserMeFactory,
    UtilsFactory,
    $translate,
    PARAMS
  ) {
    var vm = this;
    vm.components = [
      {
        name: 'RESERVATIONS',
        isVisible: true,
        init: function () {}
      },
      {
        name: 'MYRESERVATIONS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'MYJOURNALS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'HISTORY',
        isVisible: false,
        init: initHistory
      },
      {
        name: 'INDIVIDUALTICKETS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'SHOP',
        isVisible: false,
        init: initWebshop
      },
      {
        name: 'CART',
        isVisible: false,
        init: initWebshopCart
      }
    ];

    /* ----- PARAMS ----- */
    vm.multipleSubscribeActionIsOpen = false;
    vm.eventsForDispayStatusMessage = [];
    vm.failedLogin = false;
    vm.selectUserContextFailed = false;
    vm.contactHasCredits = false;
    vm.contactFitnessCredits = null;
    vm.calendar = {};
    vm.categories = {};
    vm.customer = {
      id: $stateParams.Id
    };
    vm.customerContact = {};
    vm.fitnessEvents = [];
    vm.fitnessEventsAreLoading = true;
    vm.participationHistory = [];
    vm.journalModalInstance = null;
    vm.journals = {};
    vm.journalTypes = {};
    vm.products = [];
    vm.cartItems = {};
    vm.cartTotals = 0;
    vm.cartIndividualItems = {};
    vm.cartIndividualTotals = 0;
    vm.guest = {};
    vm.selectedDate = false;
    vm.selectedCategory = false;
    vm.showingJournalModal = false;
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };
    vm.calendarIsVisible = false;
    vm.historyIsVisible = false;
    vm.webshopIsVisible = false;
    vm.webshopCartIsVisible = false;
    vm.webshopSaleIsLoading = false;

    vm.filterPosition = [];

    vm.environments = ENVIRONMENTS.filter(function (e) {
      if (angular.isUndefined(e.visibleFrom)) {
        return true;
      }
      return (new Date(e.visibleFrom) <= new Date());
    });
    vm.currentSitesData = [];

    vm.config = [];
    vm.selectedBackend = 0;
    // set root UI variables
    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['kiosk']
    };
    vm.registeredEvents = 0;
    vm.availableCredits = 0;

    vm.passwordModalInstance = null;
    vm.passwordModalIsOpen = false;
    vm.newAccountModalInstance = null;
    vm.newAccountIsOpen = false;
    vm.selectActionIsOpen = false;
    vm.renewJournalModalInstance = null;
    vm.renewJournalIsOpen = false;
    vm.currentLocale = $translate.use();
    vm.defaultMaxProductsNumber = 9;
    vm.maxProductsNumber = vm.defaultMaxProductsNumber;
    vm.individualTicketsCount = 0;
    vm.myFitnessEvents = [];
    vm.editableFitnessEvents = [];
    vm.individualTicketsDatePickerIsOpened = false;
    vm.individualTicketsDatePickerMinDate = new Date();
    vm.eventsDate = new Date();
    vm.eventsDatePickerIsOpened = false;
    vm.currentDate = new Date();
    vm.eventsDatePickerMinDate = new Date(vm.currentDate.toLocaleString('en-US', { timeZone: 'Europe/Brussels' }));
    vm.currentPurchaseStep = 1;
    vm.purchaseProducts = [];
    vm.activePurchaseResidentButton = null;
    vm.individualTicketsDate = null;
    vm.eventsForIndividualTickets = [];
    vm.activityTypeForIndividualEvents = 'Recreatiebad';
    vm.selectedEventInstanceForIndividualTickets = null;
    vm.selectedEventInstanceForEdit = null;
    vm.noEventsWithAvailablePlacesForIndividual = false;
    vm.searchEventsDone = false;
    vm.promocodeDiscount = null;
    vm.cartDiscount = null;
    vm.cartIndividualTotalDiscount = null;
    vm.cartTotalDiscount = null;
    vm.promocodeValid = false;
    vm.promocodeChecked = false;
    vm.needReloadEvents = false;
    vm.waitForCustomerInfo = true;
    vm.displayLoginScreen = true;
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.back = back;
    vm.changeStatus = changeStatus;
    vm.createNewAccount = createNewAccount;
    vm.dateToSeconds = dateToSeconds;
    vm.enableWebshop = false;
    vm.getAvailablePlacesText = getAvailablePlacesText;
    vm.getButtonText = getButtonText;
    vm.isDisplayAttentionMessage = isDisplayAttentionMessage;
    vm.isVisible = isVisible;
    vm.isComponentVisible = isComponentVisible;
    vm.loadCompleteFitnessData = loadCompleteFitnessData;
    vm.loadConfig = loadConfig;
    vm.loadCustomerData = loadCustomerData;
    vm.loadFitnessCredits = loadFitnessCredits;
    vm.loadFitnessEvents = loadFitnessEvents;
    vm.login = login;
    vm.registerAsGuest = registerAsGuest;
    vm.loginAsGuest = loginAsGuest;
    vm.logout = logout;
    vm.passwordForgot = passwordForgot;
    vm.returnOfPasswordModalInstance = returnOfPasswordModalInstance;
    vm.selectAction = selectAction;
    vm.selectCategory = selectCategory;
    vm.selectDate = selectDate;
    vm.showButton = showButton;
    vm.showCalendar = showCalendar;
    vm.showComponent = showComponent;
    vm.subscribeButtonDisabled = subscribeButtonDisabled;
    vm.initWebshopCart = initWebshopCart;
    vm.initHistory = initHistory;
    vm.initWebshop = initWebshop;
    vm.updateCategories = updateCategories;
    vm.checkForOneTimeDispkayEvent = checkForOneTimeDispkayEvent;
    vm.changeLocale = changeLocale;
    vm.getProductByTypeLabel = getProductByTypeLabel;
    vm.filterByEntrances = filterByEntrances;
    vm.allProducts = null;
    vm.getRejects = getRejects;
    vm.rejectionArray = [];
    vm.cancelParticipation = cancelParticipation;
    vm.getProductList = getProductList;
    vm.nextPurchaseStep = nextPurchaseStep;
    vm.previousPurchaseStep = previousPurchaseStep;
    vm.getProductListByCategories = getProductListByCategories;
    vm.getEventsForIndividualTickets = getEventsForIndividualTickets;
    vm.selectEventInstance = selectEventInstance;
    vm.editEventInstance = editEventInstance;
    vm.changeEventInstance = changeEventInstance;
    vm.checkPromocode = checkPromocode;
    vm.promocodeValue = '';
    vm.setPromocode = setPromocode;
    vm.getEventsForCustomer = getEventsForCustomer;
    vm.getReservationsForCustomer = getReservationsForCustomer;
    vm.filterViaActivity = filterViaActivity;
    vm.openNav = 'width: 0px; margin-left: 0px';
    vm.openSidebar = openSidebar;
    vm.actionSelectionModalInstance = null;
    vm.actionSelectionIsOpen = false;
    vm.displayDialog = displayDialog;
    vm.closeDialog = closeDialog;
    vm.guestEventUpdated = $rootScope.guestEventUpdated;
    vm.loadBackendConfig = loadBackendConfig;
    vm.onlineReservationDisabled = angular.isDefined(PARAMS.settings) && angular.isDefined(PARAMS.settings.onlineReservationDisabled) ? PARAMS.settings.onlineReservationDisabled : false;

    /* ----- END FUNCTIONS ----- */

    /* ----- ON INIT ----- */
    //if access to login adn site is disabled then return back to main page
    if ($state.current.name === 'kiosk.login' && vm.onlineReservationDisabled) {
      $state.go('kiosk');
    }

    if ($state.current.name === 'kiosk.customer' || $state.current.name === 'kiosk.guest') {
      RestUtilsFactory.getFullList(EventParticipantStatusFactory, {limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
        vm.participantStatuses = resultStatuses;
      });
      EventFactory.one('participants').getList('types', {limit: 99, sort: 'label,asc'}).then(function (resultTypes) {
        vm.participantTypes = resultTypes;
      });
      SettingsService.reloadSettings().then(function () {
        console.log('set settings');
        vm.enableWebshop = angular.isDefined($localStorage.enableWebshop) || SettingsService.get('settings.enableWebshop', false);
      });

      // check if webshop sale was completed
      if ($location.search().sale_id) {
        vm.webshopSaleIsLoading = true;

        SaleFactory.checkMolliePayment($location.search().sale_id).then(function () {
          SaleFactory.isSaleComplete($location.search().sale_id).then(function (res) {
            vm.webshopSaleIsLoading = false;
            if (!res.completed) {
              ToastrNotificationService.showTranslatedNotification('warning', 'kiosk.webshop', 'product.buy_error');
            } else if (res.guest) {
              // guest users should see a thank you page after a successfull payment
              vm.currentPurchaseStep = 5;
            } else {
              ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', (res.withReservation ? 'product.buy_with_reservation_success' : 'product.buy_success'));
            }
          });
        });
      }

      vm.loadCustomerData()
        .then(function () {
          vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
            if (cc.firstName === 'Proefbeurt') {
              console.log('test user put webshop on');
              vm.enableWebshop = true;
            }
            return cc.customerContactType.code === 'USER';
          })[0];
          vm.loadCompleteFitnessData();
        });

      $interval(function () {
        UserMeFactory.one().get().then(function () {
          $log.debug('Reloaded the token !!!');
        });
      }, 600000);
    }

    if ($state.current.name === 'kiosk.guest_edit') {
      vm.loadCustomerData().then(function () {
        vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
          return cc.customerContactType.code === 'USER';
        })[0];
        getEditableReservationsForCustomer();
      });
    }

    if ($state.current.name === 'kiosk.guest_login') {
      setEnvironmentFromLocalStorage(true).then(function () {
        if (angular.isUndefined($rootScope.loggingInAsGuest)) {
          $rootScope.loggingInAsGuest = true;
          return vm.loginAsGuest();
        }
      }, function () {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk.choose_site');
      });
    }

    /* ----- END ON INIT ----- */

    $scope.$on('$viewContentLoaded', function () {
      setEnvironmentFromLocalStorage();
    });

    function setEnvironmentFromLocalStorage(returnPromise) {
      if ($localStorage.selectedBackend || (!$localStorage.selectedBackend && $localStorage.selectedBackend === 0)) {
        return $timeout(function () {
          $rootScope.environment = vm.environments[$localStorage.selectedBackend];
        });
      }
      if (angular.isDefined(returnPromise)) {
        return Promise.reject(new Error('no selected backend in localStorage'));
      }
    }

    function filterViaActivity(activityName) {
      if (activityName === 'swimming') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasSwimming;
        });
      }

      if (activityName === 'fitness') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasFitness;
        });
      }

      if (activityName === 'group lessons') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasGroupLessons;
        });
      }

      vm.filterPosition = vm.currentSitesData.map(function (element, index) {
        return {
          position: element.mapPosition,
          name: element.name,
          city: element.city,
          image: element.image,
          index: index
        };
      });
    }

    function createNewAccount() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_create_account.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }

    function selectAction() {
      if (vm.selectActionIsOpen) {
        return;
      }
      vm.selectActionModalInstance = $modal.open({
        // templateUrl: 'kiosk/views/user_select_action.modal.tpl.html',
        // controller: 'UserSelectActionAccountCtrl',
        // controllerAs: 'userSelectActionAccountCtrl'
        templateUrl: 'kiosk/views/create_new_account_for_new_customer.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl',
        resolve: {
          email: function () {
            return angular.isDefined(vm.credentials) && angular.isDefined(vm.credentials.email) ? vm.credentials.email : null;
          }
        }
      });
      vm.selectActionIsOpen = true;

      vm.selectActionModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.selectActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.selectActionIsOpen = false;
      });
    }

    function passwordForgot() {
      // Show modal
      // Check if the modal view is already open
      if (vm.passwordModalIsOpen) {
        return;
      }
      // Open a modal view from specifi c template and controller. A customer object and customerContact object are added to the controller
      vm.passwordModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_password_forgot.modal.tpl.html',
        controller: 'UserPasswordForgotCtrl',
        controllerAs: 'userPasswordForgotCtrl'
      });
      vm.passwordModalIsOpen = true;
      vm.returnOfPasswordModalInstance();
    }

    function returnOfPasswordModalInstance() {
      vm.passwordModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      });
    }

    function getRejects(eventInstance) {
      var rejectionArray = [];
      eventInstance.eventParticipants.forEach(function (participant) {
        if (participant.eventParticipantStatus.code === 'ACCEPTED' && participant.eventParticipantType.code === 'PARTICIPANT' && vm.customerContact.contact.id === participant.contact.id) {
          rejectionArray.push(participant);
          // console.log(participant);
        }
      });
      // console.log(eventInstance);
      return rejectionArray;
    }

    function cancelParticipation(eventParticipant) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          var deferred = $q.defer(),
              participantObject = {
                eventParticipantStatus: rejectedStatus.id
              };
          // Set Rejected
          EventParticipantFactory.one(eventParticipant.id).patch(participantObject).then(function () {
            //reload data
            vm.loadCompleteFitnessData();
          });
          return deferred.promise;
        });
    }

    function loadCompleteFitnessData() {
      vm.availableCredits = null;
      vm.registeredEvents = 0;
      vm.fitnessEventsAreLoading = true;
      return FitnessFactory.loadCompleteFitnessData(vm.customer, vm.customerContact)
        .then(function (fitnessData) {
          $log.debug('KioskCtrl::loadFitnessEvents() -> FitnessData:', fitnessData);
          vm.contactFitnessCredits = fitnessData.credits;

          vm.journals = {};
          vm.journalTypes = {};
          angular.forEach(fitnessData.activeJournals, function (journal) {
            vm.journals[journal.id] = journal;
            if (angular.isDefined(vm.journalTypes[journal.journalType.id])) {
              vm.journalTypes[journal.journalType.id].push(journal.id);
            } else {
              vm.journalTypes[journal.journalType.id] = [journal.id];
            }
          });
          vm.waitForCustomerInfo = false;
          if (vm.contactFitnessCredits > 0) {
            vm.contactHasCredits = true;
          }

          vm.getReservationsForCustomer();
          if (vm.needReloadEvents) {
            return vm.getEventsForCustomer();
          }
        });
    }

    function changeStatus(status, eventInstance) {
      $log.debug('status: ', status);
      $log.debug('Kiosk: event: ', eventInstance.label);
      switch (status) {
        case 'cancel':
        case 'cancel-waitinglist':
          return FitnessEventParticipationService.multipleCancel(eventInstance).then(function () {
            $log.debug('Kiosk: event participation cancelled');
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation multiple cancel failed');
          });
        // return FitnessEventParticipationService.cancel(eventInstance).then(function () {
        //   $log.debug('Kiosk: event participation cancelled');
        //   return vm.loadCompleteFitnessData();
        // }, function () {
        //   $log.debug('Kiosk: event participation cancel failed');
        // });
        case 'subscribe':
          if (checkInstaceForMultipleSubscribing(eventInstance)) {
            return multipleSubscribe(eventInstance, vm.customerContact);
          }
          //extract to method single subscribe
          return FitnessEventParticipationService.subscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        case 'extra-subscribe':
          return FitnessEventParticipationService.extraSubscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        default:
          return Promise.reject();
      }
    }

    function checkInstaceForMultipleSubscribing(eventInstance) {
      var result = false;
      angular.forEach(vm.journals, function (journal) {
        if (journal.journalType.multipleConsumptions) {
          eventInstance.eligibleJournalTypeIds.forEach(function (element) {
            if (journal.journalType.id === element && journal.journalType.multipleConsumptions === true) {
              result = true;
            }
          });
        }
      });
      return result;
    }

    function checkForOneTimeDispkayEvent(idEvent) {
      if (vm.eventsForDispayStatusMessage.includes(idEvent)) {
        return true;
      }
      return false;
    }
    function multipleSubscribe(eventInstance, customerContact) {
      var i = 0, promicesOfSubscribes = [];
      if (vm.multipleSubscribeActionIsOpen) {
        return;
      }
      vm.multipleSubscribeInstance = $modal.open({
        templateUrl: 'kiosk/views/multiple_subscribe.modal.tpl.html',
        controller: 'MultipleSubscribeCtrl',
        controllerAs: 'multipleSubscribeCtrl'
      });
      vm.multipleSubscribeInstance.availableCredits = vm.contactFitnessCredits;
      vm.multipleSubscribeActionIsOpen = true;
      vm.multipleSubscribeInstance.result.then(function (returnValue) {
        for (i = 0; i < vm.multipleSubscribeInstance.count; i++) {
          console.log('suscribe -> ' + i);
          promicesOfSubscribes.push(new Promise(function (res) {
            return res(FitnessEventParticipationService.subscribe(eventInstance, customerContact));
          }));
        }
        Promise.all(promicesOfSubscribes).then(function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          return vm.loadCompleteFitnessData();
        }, function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          return vm.loadCompleteFitnessData();
        });

        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      });
      // return vm.loadCompleteFitnessData();
    }

    function dateToSeconds(dateString) {
      return moment(dateString).unix();
    }

    function isVisible(eventInstance) {
      return eventInstance.eventCategories.filter(function (eventCategory) {
        return eventCategory.code === vm.selectedCategory || vm.selectedCategory === false;
      }).length;
    }

    function loadCustomerData() {
      return MyEntitiesFactory.getCustomer()
        .then(function (customer) {
          vm.customer = customer;
        });
    }

    function loadFitnessCredits() {
      return MyEntitiesFactory
        .getFitnessCredits()
        .then(function (resultCredits) {
          $log.debug('fitness credits', resultCredits);
          if (!angular.isArray(resultCredits)) {
            vm.contactFitnessCredits = resultCredits.credits;
            vm.contactHasCredits = true;
          } else {
            vm.contactHasCredits = false;
          }
        });
    }

    function loadFitnessEvents() {
      return FitnessFactory.loadFitnessEvents(vm.journals, vm.customerContact).then(function (results) {
        $log.debug('FitnessEvents: ', results);
        vm.fitnessEvents = results.events;
      });
    }

    function login() {
      vm.failedLogin = false;
      return OAuth.getAccessToken(vm.credentials)
        .then(function (response) {
          $log.debug('KIOSK: Form authentication successful', response);

          return fetchUser().then(function (customer) {
            vm.displayLoginScreen = false;
            $state.go('kiosk.customer', {customerId: customer.id});
          });
        }, function (errorResponse) {
          vm.failedLogin = true;
          $log.debug('KIOSK: Form authentication failed' + errorResponse);
        });
    }

    function registerAsGuest() {
      return vm.logout(true).finally(function () {
        return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-and-login-as-guest')
          .customPOST({
            site: $rootScope.environment.siteId,
            locale: $translate.use().split('_')[0].toLowerCase()
          }).then(function (response) {
            $log.debug('KIOSK: Guest authentication successful', response);

            OAuthToken.setToken(response);

            return fetchUser().then(function (customer) {
              $state.go('kiosk.guest', {customerId: customer.id});
            });
          }, function (errorResponse) {
            $log.debug('KIOSK: Guest authentication failed' + errorResponse);
          });
      });
    }

    function loginAsGuest() {
      return vm.logout(true).finally(function () {
        return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/login-as-guest')
          .customPOST({
            token: $stateParams.token
          }).then(function (response) {
            $log.debug('KIOSK: Guest login successful', response);

            OAuthToken.setToken(response);

            return fetchUser().then(function (customer) {
              $state.go('kiosk.guest_edit', {customerId: customer.id});
            });
          }, function (errorResponse) {
            $log.debug('KIOSK: Guest login failed' + errorResponse);
          });
      });
    }

    function fetchUser() {
      return UserMeFactory.one().get()
        .then(function (user) {
          $log.debug('KIOSK: Fetched user' + user);
          LocaleService.syncBackendLocale(user.locale);
          return CurrentUserContextFactory.autoSelectUserContext(user)
            .then(function () {
              return MyEntitiesFactory.getCustomer()
                .then(function (customer) {
                  if (customer) {
                    $log.debug('KIOSK: Fetched customer linked to user' + customer);
                    return Promise.resolve(customer);
                  }
                  vm.failedLogin = true;
                  $log.debug('KIOSK: No customer found linked to user' + user);
                  return Promise.reject();
                }, function (errorResponse) {
                  vm.failedLogin = true;
                  $log.debug('KIOSK: Failed to fetch customer linked to user' + errorResponse);
                });
            }, function (error) {
              vm.failedLogin = true;
              vm.selectUserContextFailed = true;
              $log.debug('KIOSK: ' + error);
              OAuth.revokeToken();
            });
        }, function (errorResponse) {
          vm.failedLogin = true;
          $log.debug('KIOSK: Failed to fetch user' + errorResponse);
        });
    }

    function logout(noRedirect) {
      var token = OAuthToken.getRefreshToken() ? OAuthToken.getRefreshToken() : OAuthToken.getAccessToken();
      vm.customerContact = {};
      vm.customer = {};
      vm.contactHasCredits = false;
      vm.contactFitnessCredits = null;
      angular.forEach($cookies, function (chip) {
        $cookies.remove(chip);
      });
      if (token) {
        return OAuth.revokeToken().then(function () {
          if (!noRedirect) {
            $state.go('kiosk');
          }
        });
      }

      return Promise.resolve();
    }

    function selectDate(date) {
      vm.selectedDate = date;
      vm.showCalendar(false);
    }

    function selectCategory(category) {
      vm.selectedCategory = vm.selectedCategory === category.code ? false : category.code;
    }

    function getButtonText(eventInstance, returnClass) {
      var valueToReturn = '';
      returnClass = angular.isUndefined(returnClass) ? false : returnClass;

      if (!eventInstance.numberOfVacancies) {
        if (eventInstance.userStatus && eventInstance.userStatus.code === 'TENTATIVE') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.confirm_tentative_status';
        } else {
          valueToReturn = returnClass ? 'btn-warning' : 'app.subscribe_to_waiting_list';
        }
      } else {
        $log.debug('Kiosk: has places available. Return correct text.');
        if (eventInstance.userStatus && eventInstance.userStatus.code === 'TENTATIVE') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.confirm_tentative_status';
        } else {
          valueToReturn = returnClass ? 'btn-primary' : 'app.subscribe';
        }
      }
      return valueToReturn;
    }

    function getAvailablePlacesText(eventInstance) {
      var valueToReturn = '';
      if (!eventInstance.numberOfVacancies) {
        valueToReturn = 'kiosk.waiting_list';
      } else {
        $log.debug('Kiosk: has places available. Return correct text.');
        if (eventInstance.numberOfVacancies > 1) {
          valueToReturn = 'kiosk.places_available';
        } else {
          valueToReturn = 'kiosk.place_available';
        }
      }
      return valueToReturn;
    }

    function isComponentVisible(component) {
      var comp = vm.components.filter(function (c) {
        return c.name === component;
      });
      return comp[0].isVisible;
    }

    function showComponent(component) {
      vm.components.filter(function (c) {
        return c.name !== component;
      }).forEach(function (c) {
        c.isVisible = false;
      });

      vm.components.filter(function (c) {
        return c.name === component;
      }).forEach(function (c) {
        c.isVisible = true;
        c.init();
      });
    }

    function showButton(action, eventInstance) {
      var isTentative = false,
          isAccepted = false,
          isPending = false,
          isPendingWithReservationSale = false,
          participates = angular.isDefined(eventInstance.userStatus),
          attends = angular.isDefined(eventInstance.userStatus) &&
          angular.isDefined(eventInstance.userStatus.code) &&
          eventInstance.userStatus.code === 'ATTENDED';

      if (eventInstance.userStatus) {
        isTentative = eventInstance.userStatus.code === 'TENTATIVE';
        isAccepted = eventInstance.userStatus.code === 'ACCEPTED';
        isPending = eventInstance.userStatus.code === 'PENDING';
        isPendingWithReservationSale = eventInstance.userStatus.code === 'PENDING' && eventInstance.userStatus.hasReservationSale;
      }

      switch (action) {
        case 'subscribe':
          if (!isAccepted &&
            !isPending &&
            !attends &&
            (!isTentative || (isTentative && eventInstance.numberOfVacancies > 0)) &&
            // vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 3 * 60 * 60)
            vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - eventInstance.reservationHours * 60 * 60)
          ) {
            // can subscribe until 3 hours before the event
            return true;
          }

          break;
        case 'cancel':
          if ((isAccepted || isTentative) &&
            vm.now.seconds < vm.dateToSeconds(eventInstance.registrationDeadline)
          ) {
            return true;
          }
          break;
        case 'cancel-waitinglist':
          if (participates &&
            isPending &&
            !isPendingWithReservationSale
          ) {
            return true;
          }
          break;
        case 'reject':
          if (threeHoursCancellation(eventInstance)) {
            return true;
          }
          if (!threeHoursCancellation(eventInstance)) {
            return false;
          }
          break;
        default:
      }
      return false;
    }

    function threeHoursCancellation(eventInstance) {
      return vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 3 * 60 * 60);
    }

    function showCalendar(status) {
      vm.calendarIsVisible = angular.isDefined(status) ? status : true;
    }

    function initHistory() {
      var parameters = {
        limit: 99
      };

      vm.participationHistory = [];
      parameters['filter[]'] = ['contact.id,' + vm.customerContact.contact.id];
      parameters['filter[]'].push('eventParticipantStatus.code,ATTENDED');
      $log.debug(parameters);

      RestUtilsFactory.getFullList(EventParticipantFactory, parameters).then(function (participationHistory) {
        $log.debug(participationHistory);
        vm.participationHistory = participationHistory;
      });
    }

    function initWebshop() {
      vm.products = [];
      // ProductFactory.getProducts().then(function (products) {
      // ProductFactory.getProductsByLabelType('individuele toegangen').then(function (products) {
      //   // console.log(products);
      //   vm.products = products;
      // });
    }

    function getProductByTypeLabel(label, subcategory) {
      vm.products = [];
      if (angular.isDefined(label) && angular.isDefined(subcategory)) {
        return ProductFactory.getProductsByCategories(label, subcategory).then(function (products) {
          vm.products = products;
          vm.allProducts = products;
        });
      }

      if (label) {
        ProductFactory.getProductsByLabelType(label).then(function (result) {
          vm.products = result;
          vm.allProducts = result;
        });
      } else {
        ProductFactory.getProducts().then(function (products) {
          vm.products = products;
          vm.allProducts = products;
        });
      }
    }

    function initWebshopCart() {
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
    }

    function openSidebar(event, state) {
      event.preventDefault();
      if (state) {
        vm.openNav = 'width: 100%; max-width: 500px; margin-left: 500px';
      } else {
        vm.openNav = 'width: 0px; max-width: 0px; margin-left: 0px';
      }
    }

    vm.buyProduct = function (product) {
      return ProductFactory.buyProduct(product).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.addToCart = function (product, inputQuantity) {
      ShoppingCartService.add(product, inputQuantity);
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      vm.maxProductsNumber -= inputQuantity;
      ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', 'product.add_to_cart_success');
    };

    vm.getTicketsCount = function () {
      return ShoppingCartService.getTotalQuantity();
    };

    vm.addIndividualTicketToCart = function (product, inputQuantity) {
      ShoppingCartService.addOrRemoveIndividual(product, inputQuantity);
      vm.cartIndividualItems = ShoppingCartService.getIndividual();
      vm.cartIndividualTotals = ShoppingCartService.totalPriceIndividual();
      vm.individualTicketsCount = ShoppingCartService.countIndividual();
    };

    vm.removeFromCart = function (product) {
      vm.maxProductsNumber += ShoppingCartService.getProduct(product).quantity;
      ShoppingCartService.remove(product);
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', 'product.remove_from_cart_success');
    };

    vm.checkoutShoppingCart = function () {
      return ProductFactory.checkoutShoppingCart(ShoppingCartService.checkout()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.checkoutIndividualTicketsShoppingCart = function () {
      ShoppingCartService.setGuest(vm.guest);
      return ProductFactory.checkoutIndividualTicketsCart(ShoppingCartService.checkoutForIndividualTickets()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.renewJournal = function (product) {
      if (vm.renewJournalIsOpen) {
        return Promise.reject();
      }
      vm.renewJournalModalInstance = $modal.open({
        templateUrl: 'kiosk/views/renew_journal.modal.tpl.html',
        controller: function () {
          this.product = product;

          this.cancel = function () {
            vm.renewJournalModalInstance.dismiss();
          };

          this.renew = function () {
            vm.renewJournalModalInstance.close();
          };
        },
        controllerAs: 'renewJournalCtrl'
      });
      vm.renewJournalIsOpen = true;

      return vm.renewJournalModalInstance.result.then(function () {
        vm.renewJournalIsOpen = false;
        return vm.buyProduct(product);
      }, function () {
        vm.renewJournalIsOpen = false;
        return Promise.reject();
      });
    };

    vm.switchUserLocale = function () {
      $modal.open({
        size: 'sm',
        templateUrl: 'kiosk/views/switch-user-locale.modal.tpl.html',
        controller: ['LOCALES', function (LOCALES) {
          this.locales = LOCALES.locales;
        }],
        controllerAs: 'switchUserLocaleCtrl'
      })
        .result
        // handle close
        .then(function (locale) {
          LocaleService.setLocale(locale, true);
        });
    };

    function updateCategories(category) {
      category.displayFlag = !category.displayFlag;
      vm.fitnessEvents.forEach(function (event) {
        event.fitnessEvent.eventCategories.forEach(function (eventCategory) {
          if (eventCategory.code === category.code) {
            eventCategory.displayFlag = category.displayFlag;
          }
        });
      });
      return category;
    }

    function loadConfig(selectedIndex) {
      vm.selectedBackend = selectedIndex;
      $localStorage.selectedBackend = vm.selectedBackend;
      $localStorage.selectedEnvironment = vm.environments[$localStorage.selectedBackend];
      $timeout(function () {
        $rootScope.environment = vm.environments[$localStorage.selectedBackend];
      });
      if (!UtilsFactory.isNotEmpty($localStorage.redirectLink)) {
        $window.location.href = '/login';
        //$location.path('login');
      } else {
        $location.path($localStorage.redirectLink);
      }
    }

    function back() {
      $state.go('kiosk');
    }

    function subscribeButtonDisabled(eventInstance) {
      var availableCredits = 0;
      angular.forEach(eventInstance.eligibleJournalTypeIds, function (eligibleJournalType) {
        angular.forEach(vm.journalTypes[eligibleJournalType], function (journalId) {
          if (vm.journals[journalId].credits !== null) {
            availableCredits += vm.journals[journalId].credits;
          }
        });
      });

      return (vm.registeredEvents >= availableCredits || vm.contactFitnessCredits <= 0);
    }

    function isDisplayAttentionMessage(eventInstance) {
      var eventStartTimeInSeconds = vm.dateToSeconds(eventInstance.startsAt);
      return (vm.now.seconds > (eventStartTimeInSeconds - (eventInstance.reservationHours * 60 * 60)) &&
        vm.now.seconds < eventStartTimeInSeconds && (!eventInstance.userStatus || eventInstance.userStatus.code === 'REJECTED'));
    }

    function changeLocale(locale) {
      LocaleService.setLocale(locale, false);
    }

    function filterByEntrances(entrance, categoryLabel) {
      var productLabel = '';
      if (vm.currentLocale === 'FR_FR') {
        if (entrance === '(Inwoners)') {
          entrance = '(Habitants)';
        } else if (entrance === '(Niet-inwoners)') {
          entrance = '(Non Habitants)';
        }
      }
      vm.getProductList(categoryLabel).then(function (productsResult) {
        entrance = entrance.toLowerCase();
        if (entrance === '(inwoners)' || entrance === '(habitants)') {
          vm.products = [];
          productsResult.forEach(function (product) {
            productLabel = product.translatedLabel.toLowerCase();
            if (productLabel.split('(niet-inwoners)').length > 1 ||
              productLabel.split('(niet inwoners)').length > 1 ||
              productLabel.split('(non habitants)').length > 1 ||
              productLabel.split('(non-habitants)').length > 1) {
              console.log(' is not (niet inwoners && niet-inwoners)');
            } else {
              vm.products.push(product);
            }
          });
        } else {
          vm.products = productsResult.filter(function (product) {
            productLabel = product.translatedLabel.toLowerCase();
            return productLabel.split(entrance).length > 1 || productLabel.split('(niet inwoners)').length > 1;
          });
        }
      });
    }

    function getProductList(label) {
      var defer = $q.defer();
      defer.resolve(vm.allProducts ? vm.allProducts : ProductFactory.getProductsByLabelType(label));
      return defer.promise;
    }

    vm.showWebshopCartMenuItem = function () {
      return Object.keys(vm.cartItems).length > 0;
    };

    vm.isNewCustomer = function () {
      return !UtilsFactory.isNotEmpty(vm.fitnessEvents) && !UtilsFactory.isNotEmpty(vm.journals);
    };

    vm.openIndividualTicketsDatePicker = function () {
      vm.individualTicketsDatePickerIsOpened = true;
    };

    vm.openEventsDatePicker = function () {
      vm.eventsDatePickerIsOpened = true;
    };

    vm.activitysFromEnv = function () {
      return vm.environments[$localStorage.selectedBackend].activityTypeForIndividualEvents;
    };

    function previousPurchaseStep() {
      vm.currentPurchaseStep--;
    }

    function nextPurchaseStep() {
      vm.currentPurchaseStep++;
    }

    function getProductListByCategories(category, subcategory) {
      vm.purchaseProducts = [];
      vm.activePurchaseResidentButton = subcategory;
      if (category && subcategory) {
        ProductFactory.getProductsByCategories(category, subcategory).then(function (products) {
          vm.purchaseProducts = products;
        });
      } else {
        ProductFactory.getProducts().then(function (products) {
          vm.purchaseProducts = products;
        });
      }
    }

    function getEventsForIndividualTickets(returnOnly) {
      return EventFactory.one('instances')
        .one('individual_upcoming')
        .one(vm.customer.id)
        .one('contact')
        .one(vm.customerContact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC',
          startsAt: $filter('date')(vm.individualTicketsDate, 'yyyy-MM-dd'),
          'filter[]': 'label,LIKE ' + vm.activityTypeForIndividualEvents
        }).then(function (result) {
          var eventInstances = result.filter(function (eventInstance) {
            return (!vm.selectedEventInstanceForEdit ||
              (vm.selectedEventInstanceForEdit.id !== eventInstance.id && vm.selectedEventInstanceForEdit.reservationCount <= eventInstance.numberOfVacancies)
            );
          });
          if (returnOnly === true) {
            return eventInstances;
          }
          vm.eventsForIndividualTickets = eventInstances;
          vm.noEventsWithAvailablePlacesForIndividual = true;
          vm.eventsForIndividualTickets.forEach(function (eventInstance) {
            if (eventInstance.numberOfVacancies > 0 && vm.now.seconds < vm.dateToSeconds(eventInstance.startsAt)) {
              vm.noEventsWithAvailablePlacesForIndividual = false;
            }
          });
          vm.searchEventsDone = true;
        });
    }

    function selectEventInstance(eventInstance) {
      ShoppingCartService.setReservationEvent(eventInstance);
      vm.selectedEventInstanceForIndividualTickets = eventInstance;
      vm.currentPurchaseStep++;
    }

    function checkPromocode(individual) {
      var productsData = angular.isDefined(individual) ? ShoppingCartService.getIndividualProductsData() : ShoppingCartService.getProductsData();
      if (vm.promocodeValue !== '') {
        PromocodeFactory.checkPromocode(vm.promocodeValue, vm.customer.id, productsData).then(function (result) {
          vm.promocodeValid = result.valid;
          vm.promocodeChecked = true;
          vm.setPromocode();
          if (result.valid) {
            vm.setPromocode(result.promocode, result.percent);
          }
        });
      }
    }

    function setPromocode(promocode, percent) {
      ShoppingCartService.setPromocode(promocode);
      ShoppingCartService.setPromocodePercent(percent);
      vm.promocodePercent = percent;
      vm.cartTotals = ShoppingCartService.getCartTotalsWithPromocode();
      vm.cartTotalDiscount = ShoppingCartService.getCartTotalDiscount();
      vm.cartIndividualTotalDiscount = ShoppingCartService.getCartIndividualTotalDiscount();
      vm.cartIndividualTotals = ShoppingCartService.getCartIndividualTotalsWithPromocode();
    }

    function getEventsForCustomer() {
      var loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });
      return EventFactory.one('instances')
        .one('upcoming_for_customer')
        .one(vm.customer.id)
        .one('contact')
        .one(vm.customerContact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC',
          startsAt: $filter('date')(vm.eventsDate, 'yyyy-MM-dd')
        }).then(function (result) {
          var eventCategoriesTranslatedLabels = {};
          vm.fitnessEvents = result;

          angular.forEach(result, function (eventInstance) {
            eventInstance.forReject = vm.getRejects(eventInstance);
            // calculate calendar data
            eventInstance.date = moment(eventInstance.startsAt).format('YYYY-MM-DD');
            if (angular.isUndefined(vm.calendar[eventInstance.date])) {
              vm.calendar[eventInstance.date] = {
                label: eventInstance.date
              };
            }

            if (angular.isDefined(eventInstance.userStatus) && (eventInstance.userStatus.code === 'ACCEPTED' ||
              eventInstance.userStatus.code === 'PENDING' ||
              eventInstance.userStatus.code === 'TENTATIVE')) {
              vm.registeredEvents++;
            }

            eventInstance.eligibleJournalTypeIds = [];
            eventInstance.classes = [];
            eventInstance.categories = {};
            eventInstance.calendar = {};

            angular.forEach(eventInstance.eventCategories, function (eventCategory) {
              eventCategoriesTranslatedLabels[eventCategory.id] = eventCategory.translatedLabel;
            });

            angular.forEach(eventInstance.eventCategories, function (eventCategory) {
              eventCategory.displayFlag = true;

              // categories
              if (angular.isUndefined(vm.categories[eventCategory.code])) {
                vm.categories[eventCategory.code] = {
                  code: eventCategory.code,
                  label: eventCategory.label,
                  translatedLabel: eventCategoriesTranslatedLabels[eventCategory.id]
                };
              }
              eventInstance.classes.push(eventCategory.code);
            });
            // override classes with fitness course color tag if set
            if (angular.isDefined(eventInstance.fitnessCourses) && angular.isDefined(eventInstance.fitnessCourses[0]) &&
              eventInstance.fitnessCourses[0].tags && angular.isDefined(eventInstance.fitnessCourses[0].tags[0]) &&
              angular.isDefined(eventInstance.fitnessCourses[0].tags[0].id)
            ) {
              eventInstance.classes = [eventInstance.fitnessCourses[0].tags[0].id];
            }
            // calendar
            eventInstance.date = moment(eventInstance.startsAt).format('YYYY-MM-DD');
            if (angular.isUndefined(eventInstance.calendar[eventInstance.date])) {
              eventInstance.calendar[eventInstance.date] = {
                label: eventInstance.date
              };
            }

            // collect eligible journal types
            angular.forEach(eventInstance.fitnessCourses, function (currentFitnessCourse) {
              angular.forEach(currentFitnessCourse.journalTypes, function (currentJournalType) {
                if (eventInstance.eligibleJournalTypeIds.indexOf(currentJournalType.id) === -1) {
                  eventInstance.eligibleJournalTypeIds.push(currentJournalType.id);
                }
              });
            });

            // calculate latest confirmation date
            eventInstance.maxConfirmationDate = moment(eventInstance.startsAt).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
          });
          loadingModal.close();
          vm.fitnessEventsAreLoading = false;
          vm.needReloadEvents = true;
        });
    }

    function getReservationsForCustomer() {
      return EventFactory.one('instances')
        .one('reservations')
        .one('contact')
        .one(vm.customerContact.contact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC'
        }).then(function (result) {
          vm.myFitnessEvents = [];
          angular.forEach(result, function (eventInstance) {
            eventInstance.forReject = vm.getRejects(eventInstance);
            vm.myFitnessEvents.push(eventInstance);
          });
        });
    }

    function getEditableReservationsForCustomer() {
      vm.fitnessEventsAreLoading = true;
      return EventFactory.one('instances')
        .one('reservations')
        .one('contact')
        .one(vm.customerContact.contact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC'
        }).then(function (result) {
          vm.editableFitnessEvents = [];
          angular.forEach(result, function (eventInstance) {
            if (vm.showButton('reject', eventInstance)) {
              eventInstance.reservationCount = vm.getRejects(eventInstance).length;
              vm.editableFitnessEvents.push(eventInstance);
            }
          });
        }).finally(function () {
          vm.fitnessEventsAreLoading = false;
        });
    }

    function editEventInstance(eventInstance) {
      vm.eventsForIndividualTickets = [];
      vm.activityTypeForIndividualEvents = eventInstance.label;
      vm.searchEventsDone = false;
      vm.selectedEventInstanceForEdit = eventInstance;
    }

    function displayDialog(indexEnvironment) {
      var newEnv = [];
      vm.filterPosition.forEach(function (site) {
        if (site.index === indexEnvironment) {
          site.isShow = true;
        }
        newEnv.push(site);
      });
      vm.filterPosition = newEnv;
    }

    function closeDialog(indexEnvironment) {
      var newEnv = [];
      vm.filterPosition.forEach(function (site) {
        if (site.index === indexEnvironment) {
          site.isShow = false;
        }
        newEnv.push(site);
      });
      vm.filterPosition = newEnv;
    }
    function changeEventInstance(newEventInstance) {
      var participations = [],
          promiseQueue = [];

      if (!vm.selectedEventInstanceForEdit) {
        return Promise.reject();
      }

      participations = vm.getRejects(vm.selectedEventInstanceForEdit);
      if (participations.length === 0) {
        return Promise.reject();
      }

      return vm.getEventsForIndividualTickets(true).then(function (eventInstances) {
        var placesAvailable = false;
        // make sure places are still available
        eventInstances.forEach(function (eventInstance) {
          if (eventInstance.id === newEventInstance.id && eventInstance.numberOfVacancies >= participations.length) {
            placesAvailable = true;
          }
        });
        if (placesAvailable === false) {
          return Promise.reject();
        }
        participations.forEach(function (participation) {
          promiseQueue.push(EventParticipantFactory.one(participation.id).patch({eventInstance: newEventInstance.id}));
        });
        return Promise.all(promiseQueue).finally(function () {
          $rootScope.guestEventUpdated = true;
          return $state.reload();
        });
      });
    }

    // vm.actionSelection = function (choosenSite) {
    vm.actionSelection = function (index) {
      if (vm.actionSelectionIsOpen) {
        return Promise.reject();
      }
      vm.actionSelectionModalInstance = $modal.open({
        templateUrl: 'kiosk/views/action_selection.modal.tpl.html',
        controller: function () {
          this.selectedSiteIndex = index;

          this.registerAsGuest = function (siteIndex) {
            vm.loadBackendConfig(siteIndex).then(function () {
              vm.registerAsGuest();
            });
          };

          this.loadSiteConfig = function (siteIndex) {
            vm.loadConfig(siteIndex);
          };

          this.close = function () {
            vm.actionSelectionModalInstance.dismiss();
            vm.actionSelectionIsOpen = false;
          };
        },
        controllerAs: 'selectionCtrl'
      });

      vm.actionSelectionIsOpen = true;

      return vm.actionSelectionModalInstance.result.then(function () {
        vm.actionSelectionIsOpen = false;
      }, function () {
        vm.actionSelectionIsOpen = false;
        return Promise.reject();
      });
    };

    function loadBackendConfig(selectedIndex) {
      return new Promise(function (resolve) {
        vm.selectedBackend = selectedIndex;
        $localStorage.selectedBackend = vm.selectedBackend;
        $localStorage.selectedEnvironment = vm.environments[$localStorage.selectedBackend];
        $rootScope.environment = vm.environments[$localStorage.selectedBackend];
        resolve();
      });
    }
  }
}());
