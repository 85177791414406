(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name kiosk.service:ShoppingCartService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('kiosk')
    .factory('ShoppingCartService', ShoppingCartService);

  function ShoppingCartService() {
    var vm = this;
    vm.shoppingCart = {};
    vm.shoppingCartIndividual = {};
    vm.reservationEvent = undefined;
    vm.promocode = undefined;
    vm.promocodePercent = undefined;
    vm.guest = {};

    vm.add = function (product, quantity) {
      if (angular.isUndefined(quantity)) {
        quantity = 1;
      }
      if (angular.isUndefined(vm.shoppingCart[product.id])) {
        vm.shoppingCart[product.id] = {
          quantity: quantity,
          product: product
        };
      } else {
        vm.shoppingCart[product.id].quantity += quantity;
      }
    };

    vm.addOrRemoveIndividual = function (product, quantity) {
      if (angular.isUndefined(quantity)) {
        quantity = 1;
      }
      if (quantity < 0) {
        return;
      }
      if (angular.isUndefined(vm.shoppingCartIndividual[product.id])) {
        vm.shoppingCartIndividual[product.id] = {
          quantity: quantity,
          product: product
        };
      } else {
        vm.shoppingCartIndividual[product.id].quantity = quantity;
        if (vm.shoppingCartIndividual[product.id].quantity <= 0) {
          delete vm.shoppingCartIndividual[product.id];
        }
      }
    };

    vm.remove = function (product, quantity) {
      if (angular.isUndefined(vm.shoppingCart[product.id])) {
        return;
      }
      if (angular.isDefined(quantity) && quantity < vm.shoppingCart[product.id].quantity) {
        vm.shoppingCart[product.id].quantity -= quantity;
      } else {
        delete vm.shoppingCart[product.id];
      }
    };

    vm.totalPrice = function () {
      return Object.values(vm.shoppingCart)
        .map(function (item) {
          return item.quantity * item.product.webshopPrice;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.totalPriceIndividual = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          return item.quantity * item.product.webshopPrice;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.countIndividual = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          return item.quantity;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.get = function () {
      return vm.shoppingCart;
    };

    vm.getIndividual = function () {
      return vm.shoppingCartIndividual;
    };

    vm.checkout = function () {
      var payload = {
        products: {},
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null
      };
      Object.values(vm.shoppingCart).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });
      return payload;
    };

    vm.getProduct = function (product) {
      return vm.shoppingCart[product.id];
    };

    vm.setReservationEvent = function (event) {
      vm.reservationEvent = event;
    };

    vm.getReservationEvent = function () {
      return vm.reservationEvent;
    };

    vm.setPromocode = function (promocode) {
      vm.promocode = promocode;
    };

    vm.getPromocodePercent = function () {
      return vm.promocodePercent;
    };

    vm.setPromocodePercent = function (promocodePercent) {
      vm.promocodePercent = promocodePercent;
    };

    vm.getPromocode = function () {
      return vm.promocode;
    };

    vm.setGuest = function (guest) {
      vm.guest = guest;
    };

    vm.getCartIndividualTotalDiscount = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          var discount = angular.isDefined(vm.promocodePercent) ? (item.product.webshopPrice * vm.promocodePercent) / 100 : 0;
          return item.quantity * discount;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.getCartTotalDiscount = function () {
      return Object.values(vm.shoppingCart)
        .map(function (item) {
          var discount = angular.isDefined(vm.promocodePercent) ? (item.product.webshopPrice * vm.promocodePercent) / 100 : 0;
          return item.quantity * discount;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.getCartIndividualTotalsWithPromocode = function () {
      return vm.totalPriceIndividual() - vm.getCartIndividualTotalDiscount();
    };

    vm.getCartTotalsWithPromocode = function () {
      return vm.totalPrice() - vm.getCartTotalDiscount();
    };

    vm.checkoutForIndividualTickets = function () {
      var payload = {
        products: {},
        reservationEvent: vm.reservationEvent.id,
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null,
        guest: vm.guest
      };

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });

      return payload;
    };

    vm.getProductsData = function () {
      var products = {};

      Object.values(vm.shoppingCart).forEach(function (item) {
        products[item.product.id] = item.quantity;
      });

      return products;
    };

    vm.getIndividualProductsData = function () {
      var products = {};

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        products[item.product.id] = item.quantity;
      });

      return products;
    };

    vm.getTotalQuantity = function () {
      var total = 0;
      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        total += item.quantity;
      });
      return total;
    };

    return vm;
  }
}());
