(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:SaleFactory
   *
   * @description
   *
   */
  angular
    .module('kiosk')
    .factory('SaleFactory', SaleFactory);

  function SaleFactory(Restangular, $locale, $location) {
    var saleService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'payment'});
    })
    .service('sales'), redirectUrl = null;

    return {
      newMolliePayment: function (sale) {
        redirectUrl = 'https://' + $location.host() + ':' + $location.port();
        console.log(redirectUrl);
        return saleService.one(sale.id).one('new_mollie_payment').customPOST({
          locale: $locale.localeID
        });
      },
      isSaleComplete: function (saleId) {
        return saleService.one(saleId).one('webshop_status_check').get();
      },
      checkMolliePayment: function (saleId) {
        var productService = Restangular
          .withConfig(function (RestangularConfigurer) {
            RestangularConfigurer.setDefaultHeaders({'x-entity': 'payment'});
          })
          .service('open');
        return productService.one('mollie_payment_verification').customPOST({id: saleId});
      }
    };
  }
}());
