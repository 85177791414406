(function () {
  'use strict';

  /* @ngdoc object
   * @name utils
   * @description
   *
   */
  angular
    .module('utils', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name utils.factory:UtilService
   *
   * @description
   *
   */
  /* @ngInject */
  UtilService.$inject = ["$state"];
  angular
    .module('utils')
    .service('UtilService', UtilService);

  function UtilService($state) {
    var vm = this;

    vm.isNotEmpty = function (variable) {
      var notNull = angular.isDefined(variable) && variable !== null;
      if (!notNull) {
        return false;
      }
      if (angular.isString(variable) || angular.isArray(variable)) {
        return variable.length > 0;
      }
      if (angular.isObject(variable) && !angular.isDate(variable)) {
        return Object.keys(variable).length > 0;
      }

      return true;
    };

    vm.isEmpty = function (variable) {
      return !vm.isNotEmpty(variable);
    };

    vm.reloadState = function () {
      $state.go($state.current, {}, {reload: true, notify: true});
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:RestUtilsFactory
   *
   * @description
   *
   */
  RestUtilsFactory.$inject = ["$q", "$log", "UtilsFactory"];
  angular
    .module('utils')
    .factory('RestUtilsFactory', RestUtilsFactory);

  function RestUtilsFactory(
    $q,
    $log,
    UtilsFactory
  ) {
    return {
      getFullList: function (factory, parameters, subElement, list) {
        var actualParameters = parameters || {},
            actualList = list || [],
            actualSubElement = subElement || false,
            actualArguments = [];

        if (!actualParameters.limit) {
          actualParameters.limit = 99;
        }

        if (!actualParameters.offset) {
          actualParameters.offset = 0;
        }

        if (actualSubElement) {
          actualArguments.push(actualSubElement);
        }

        actualArguments.push(actualParameters);

        return $q(function (resolve, reject) {
          var offsetsToRetrieve = [],
              remainder = 0,
              blockSize = actualParameters.limit,
              currentOffsetValue = 0;
          // Call apply function on factory, passing this and argument array.
          // This call will retrieve the initial block of data, along with a count value
          // telling us the total number of results.
          factory.getList.apply(factory, actualArguments)
          .then(function (results) {
            // collect results, either from subarray "data" of just the response
            if (angular.isUndefined(results.count)) {
              angular.forEach(results.data, function (result) {
                actualList.push(result);
              });
            } else {
              angular.forEach(results, function (result) {
                actualList.push(result);
              });
            }

            // determine the number of results that still need to be fetched
            if (angular.isUndefined(results.count)) {
              if (angular.isUndefined(results.data)) {
                remainder = results.length;
              } else {
                remainder = results.data.count;
              }
            } else {
              remainder = results.count;
            }
            remainder -= blockSize;

            // set current offset value to blockSize since we already fetched the first block
            currentOffsetValue = blockSize;

            // determine all the offsets that still need to be fetched
            while (remainder > 0) {
              offsetsToRetrieve.push(currentOffsetValue);
              currentOffsetValue += blockSize;
              remainder -= blockSize;
            }

            // retrieve all the offsets asynchronously and resolve when it's done.
            UtilsFactory.promiseLoop(offsetsToRetrieve, function (offset) {
              actualParameters.offset = offset;
              return factory.getList.apply(factory, actualArguments)
              .then(function (offsetResults) {
                actualList = actualList.concat(offsetResults);
              });
            })
            .then(function () {
              $log.debug('RestUtilsFactory::getFullList() -> Fetched ' + actualList.length + ' records.');
              resolve(actualList);
            }, function (errorResponse) {
              reject(errorResponse);
            });
          }, function (errorResponse) {
            // call to apply failed for some reason
            reject(errorResponse);
          });
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name utils.factory:AttachmentUtilsFactory
   *
   * @description
   *
   */
  AttachmentUtilsFactory.$inject = ["Restangular", "OAuthToken", "$window", "$log"];
  angular
    .module('utils')
    .factory('AttachmentUtilsFactory', AttachmentUtilsFactory);

  function AttachmentUtilsFactory(Restangular, OAuthToken, $window, $log) {
    return {
      download: function (url, parameters) {
        var actualParameters = parameters || {},
            actualUrl = Restangular.configuration.baseUrl + url,
            uriObject,
            uri;

        /* eslint-disable camelcase */
        if (angular.isUndefined(actualParameters.access_token)) {
          actualParameters.access_token = OAuthToken.getAccessToken();
        }
        /* eslint-enable camelcase */

        /* global URI */
        uriObject = new URI(actualUrl);
        uriObject.setQuery(actualParameters);
        uri = uriObject.toString();

        $log.debug('ATTACHMENT_UTILS: Downloading ', uri);

        $window.open(uri, '_blank');

        return uri;
      }
    };
  }
}());

(function () {
  'use strict';

  /*
   * @ngdoc object
   * @name toastr-notification
   * @description
   */
  angular
    .module('toastrNotification', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name toastrNotification.service:ToastrNotificationService
   *
   * @description
   *
   */
  ToastrNotificationService.$inject = ["toastr", "toastrConfig", "$filter"];
  angular
    .module('toastrNotification')
    .factory('ToastrNotificationService', ToastrNotificationService);

  function ToastrNotificationService(toastr, toastrConfig, $filter) {
    var showNotification, showTranslatedNotification, showTranslatedAndFormattedNotification;
    angular.extend(toastrConfig, {
      closeButton: true,
      progressBar: true,
      timeOut: 5000,
      extended: 5000
    });

    showNotification = function (type, title, msg, options) {
      switch (type) {
        case 'info':
          toastr.info(msg, title, options);
          break;

        case 'success':
          toastr.success(msg, title, options);
          break;

        case 'error':
          toastr.error(msg, title, options);
          break;

        case 'warning':
          toastr.warning(msg, title, options);
          break;

        default:
          toastr.info(msg, title, options);
          break;
      }
    };

    showTranslatedNotification = function (type, title, msg, options) {
      showNotification(type, $filter('uconlyfirst')($filter('translate')(title)), $filter('uconlyfirst')($filter('translate')(msg)), options);
    };

    showTranslatedAndFormattedNotification = function (type, titleFormat, title, msgFormat, msg, options) {
      showNotification(
        type,
        $filter('uconlyfirst')($filter('sprintf')($filter('translate')(titleFormat), $filter('translate')(title))),
        $filter('uconlyfirst')($filter('sprintf')($filter('translate')(msgFormat), $filter('translate')(msg))),
        options
      );
    };

    return {
      showNotification: showNotification,
      showTranslatedNotification: showTranslatedNotification,
      showTranslatedAndFormattedNotification: showTranslatedAndFormattedNotification
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name settings
   * @description
   *
   */
  angular
    .module('settings', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.factory:SettingsService
   *
   * @description
   *
   */
  /* @ngInject */
  SettingsService.$inject = ["$localStorage", "$timeout", "SettingFactory"];
  angular
    .module('settings')
    .factory('SettingsService', SettingsService);

  function SettingsService(
    $localStorage,
    $timeout,
    SettingFactory
  ) {
    var vm = this;
    vm.get = get;
    vm.reloadSettings = reloadSettings;

    function get(key, defaultValue) {
      var setting = null;

      if (angular.isUndefined($localStorage.currentUserSettings)) {
        return null;
      }

      if (key in $localStorage.currentUserSettings) {
        setting = $localStorage.currentUserSettings[key];
        if (angular.isString(setting) && (setting.toLowerCase() === 'false' || setting.toLowerCase() === 'off')) {
          setting = false;
        }
      } else if (angular.isDefined(defaultValue)) {
        setting = defaultValue;
      }

      return setting;
    }

    function reloadSettings() {
      return SettingFactory.getListSimple().then(function (result) {
        $localStorage.currentUserSettings = result;
        // run angular digest so all buttons show up in the navbar
        $timeout();
      });
    }

    return vm;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name settings.service:LocaleService
   *
   * @description
   *
   */
  /* @ngInject */
  LocaleService.$inject = ["$state", "$translate", "LOCALES", "$rootScope", "amMoment", "tmhDynamicLocale", "$document", "UserMeFactory"];
  angular
    .module('settings')
    .service('LocaleService', LocaleService);

  function LocaleService($state, $translate, LOCALES, $rootScope, amMoment, tmhDynamicLocale, $document, UserMeFactory) {
    var self = this,
        locales = Object.keys(LOCALES.locales);

    // set moment.js locale on page load
    amMoment.changeLocale($translate.use());
    tmhDynamicLocale.set($translate.use().toLowerCase().replace(/_/g, '-'));

    // on successful applying translations by angular-translate
    $rootScope.$on('$translateChangeSuccess', function (event, data) {
      $document[0].documentElement.setAttribute('lang', data.language);

      // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
      tmhDynamicLocale.set(data.language.toLowerCase().replace(/_/g, '-'));
      // set moment.js locale on locale change
      amMoment.changeLocale(data.language);

      $state.reload();
    });

    self.setLocale = function (locale, persistToBackend) {
      var localeBackend;

      if (locales.indexOf(locale) === -1) {
        void 0;
        return;
      }

      if (angular.isDefined(persistToBackend) && persistToBackend === true) {
        localeBackend = self.normalizeLocaleForBackend(locale);
        UserMeFactory.one('locale').one(localeBackend).post().then(function () {
          $translate.use(locale);
        });
      } else {
        $translate.use(locale);
      }
    };

    self.toggleLocale = function () {
      var currentLocale = $translate.use(),
          count = locales.length,
          index = locales.indexOf(currentLocale),
          newIndex = (index + 1 < count) ? index + 1 : 0;

      // asking angular-translate to load and apply proper translations
      $translate.use(locales[newIndex]);
    };

    self.syncBackendLocale = function (currentBackendLocale) {
      var newBackendLocale = self.normalizeLocaleForBackend($translate.use());

      if (currentBackendLocale !== newBackendLocale) {
        UserMeFactory.one('locale').one(newBackendLocale).post();
      }
    };

    self.normalizeLocaleForBackend = function (locale) {
      var localeBackend = locale.toLowerCase();
      if (localeBackend.indexOf('_') > -1) {
        localeBackend = localeBackend.substring(0, localeBackend.indexOf('_'));
      }
      return localeBackend;
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.factory:SettingFactory
   *
   * @description
   *
   */
  /* @ngInject */
  SettingFactory.$inject = ["Restangular"];
  angular
    .module('settings')
    .factory('SettingFactory', SettingFactory);

  function SettingFactory(Restangular) {
    var settingService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'setting'});
    })
    .service('settings');

    settingService.getListSimple = function () {
      return new Promise(function (resolve, reject) {
        settingService.one('simple').customGET().then(function (response) {
          resolve(response.plain());
        }, reject);
      });
    };

    return settingService;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name kiosk
   * @description
   *
   */
  angular
    .module('kiosk', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name kiosk.service:ShoppingCartService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('kiosk')
    .factory('ShoppingCartService', ShoppingCartService);

  function ShoppingCartService() {
    var vm = this;
    vm.shoppingCart = {};
    vm.shoppingCartIndividual = {};
    vm.reservationEvent = undefined;
    vm.promocode = undefined;
    vm.promocodePercent = undefined;
    vm.guest = {};

    vm.add = function (product, quantity) {
      if (angular.isUndefined(quantity)) {
        quantity = 1;
      }
      if (angular.isUndefined(vm.shoppingCart[product.id])) {
        vm.shoppingCart[product.id] = {
          quantity: quantity,
          product: product
        };
      } else {
        vm.shoppingCart[product.id].quantity += quantity;
      }
    };

    vm.addOrRemoveIndividual = function (product, quantity) {
      if (angular.isUndefined(quantity)) {
        quantity = 1;
      }
      if (quantity < 0) {
        return;
      }
      if (angular.isUndefined(vm.shoppingCartIndividual[product.id])) {
        vm.shoppingCartIndividual[product.id] = {
          quantity: quantity,
          product: product
        };
      } else {
        vm.shoppingCartIndividual[product.id].quantity = quantity;
        if (vm.shoppingCartIndividual[product.id].quantity <= 0) {
          delete vm.shoppingCartIndividual[product.id];
        }
      }
    };

    vm.remove = function (product, quantity) {
      if (angular.isUndefined(vm.shoppingCart[product.id])) {
        return;
      }
      if (angular.isDefined(quantity) && quantity < vm.shoppingCart[product.id].quantity) {
        vm.shoppingCart[product.id].quantity -= quantity;
      } else {
        delete vm.shoppingCart[product.id];
      }
    };

    vm.totalPrice = function () {
      return Object.values(vm.shoppingCart)
        .map(function (item) {
          return item.quantity * item.product.webshopPrice;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.totalPriceIndividual = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          return item.quantity * item.product.webshopPrice;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.countIndividual = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          return item.quantity;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.get = function () {
      return vm.shoppingCart;
    };

    vm.getIndividual = function () {
      return vm.shoppingCartIndividual;
    };

    vm.checkout = function () {
      var payload = {
        products: {},
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null
      };
      Object.values(vm.shoppingCart).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });
      return payload;
    };

    vm.getProduct = function (product) {
      return vm.shoppingCart[product.id];
    };

    vm.setReservationEvent = function (event) {
      vm.reservationEvent = event;
    };

    vm.getReservationEvent = function () {
      return vm.reservationEvent;
    };

    vm.setPromocode = function (promocode) {
      vm.promocode = promocode;
    };

    vm.getPromocodePercent = function () {
      return vm.promocodePercent;
    };

    vm.setPromocodePercent = function (promocodePercent) {
      vm.promocodePercent = promocodePercent;
    };

    vm.getPromocode = function () {
      return vm.promocode;
    };

    vm.setGuest = function (guest) {
      vm.guest = guest;
    };

    vm.getCartIndividualTotalDiscount = function () {
      return Object.values(vm.shoppingCartIndividual)
        .map(function (item) {
          var discount = angular.isDefined(vm.promocodePercent) ? (item.product.webshopPrice * vm.promocodePercent) / 100 : 0;
          return item.quantity * discount;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.getCartTotalDiscount = function () {
      return Object.values(vm.shoppingCart)
        .map(function (item) {
          var discount = angular.isDefined(vm.promocodePercent) ? (item.product.webshopPrice * vm.promocodePercent) / 100 : 0;
          return item.quantity * discount;
        })
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    };

    vm.getCartIndividualTotalsWithPromocode = function () {
      return vm.totalPriceIndividual() - vm.getCartIndividualTotalDiscount();
    };

    vm.getCartTotalsWithPromocode = function () {
      return vm.totalPrice() - vm.getCartTotalDiscount();
    };

    vm.checkoutForIndividualTickets = function () {
      var payload = {
        products: {},
        reservationEvent: vm.reservationEvent.id,
        promocode: angular.isDefined(vm.promocode) ? vm.promocode.id : null,
        guest: vm.guest
      };

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        payload.products[item.product.id] = item.quantity;
      });

      return payload;
    };

    vm.getProductsData = function () {
      var products = {};

      Object.values(vm.shoppingCart).forEach(function (item) {
        products[item.product.id] = item.quantity;
      });

      return products;
    };

    vm.getIndividualProductsData = function () {
      var products = {};

      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        products[item.product.id] = item.quantity;
      });

      return products;
    };

    vm.getTotalQuantity = function () {
      var total = 0;
      Object.values(vm.shoppingCartIndividual).forEach(function (item) {
        total += item.quantity;
      });
      return total;
    };

    return vm;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:UserMeFactory
   *
   * @description
   *
   */
  UserMeFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('UserMeFactory', UserMeFactory);

  function UserMeFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'me'});
    })
    .service('me');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:SaleFactory
   *
   * @description
   *
   */
  SaleFactory.$inject = ["Restangular", "$locale", "$location"];
  angular
    .module('kiosk')
    .factory('SaleFactory', SaleFactory);

  function SaleFactory(Restangular, $locale, $location) {
    var saleService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'payment'});
    })
    .service('sales'), redirectUrl = null;

    return {
      newMolliePayment: function (sale) {
        redirectUrl = 'https://' + $location.host() + ':' + $location.port();
        void 0;
        return saleService.one(sale.id).one('new_mollie_payment').customPOST({
          locale: $locale.localeID
        });
      },
      isSaleComplete: function (saleId) {
        return saleService.one(saleId).one('webshop_status_check').get();
      },
      checkMolliePayment: function (saleId) {
        var productService = Restangular
          .withConfig(function (RestangularConfigurer) {
            RestangularConfigurer.setDefaultHeaders({'x-entity': 'payment'});
          })
          .service('open');
        return productService.one('mollie_payment_verification').customPOST({id: saleId});
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:PromocodeFactory
   *
   * @description
   *
   */
  PromocodeFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('PromocodeFactory', PromocodeFactory);

  function PromocodeFactory(Restangular) {
    var promocodeService = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'promocode'});
      })
      .service('promocode');

    // sportoase-backend.loc/api/promocode/79ddac85-dbaa-11ea-b68c-84fdd1ba2d4f
    // /promocode/{promocode_value}/customer/{customer_id}/validate

    return {
      checkPromocode: function (promocodeValue, customerId, productsData) {
        void 0;
        void 0;
        return promocodeService.one(promocodeValue).one('customer').one(customerId).one('validate').get({products: productsData});
      },
      updatePromocode: function (promocodeId, object) {
        return promocodeService.one(promocodeId).customPUT(object);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:ProductFactory
   *
   * @description
   *
   */
  ProductFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('ProductFactory', ProductFactory);

  function ProductFactory(Restangular) {
    var productService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'product'});
    })
    .service('products');

    return {
      getProducts: function () {
        return productService.one('for_webshop').get();
      },
      buyProduct: function (product) {
        return productService.one(product.id).one('buy').post();
      },
      checkoutShoppingCart: function (cart) {
        return productService.one('cart').post('checkout', cart);
      },
      checkoutIndividualTicketsCart: function (cart) {
        return productService.one('cart').post('checkout-individual-tickets', cart);
      },
      getProductsByLabelType: function (label) {
        return productService.one('for_webshop').get({category: label});
      },
      getProductsByCategories: function (label, subcategory) {
        var category = [label, subcategory];
        return productService.one('for_webshop').get({'category[]': category});
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:MyEntitiesFactory
   *
   * @description
   *
   */
  MyEntitiesFactory.$inject = ["UserMeFactory"];
  angular
    .module('kiosk')
    .factory('MyEntitiesFactory', MyEntitiesFactory);

  function MyEntitiesFactory(UserMeFactory) {
    return {
      getCustomer: function (params) {
        return UserMeFactory.one('customer').get(params);
      },
      getContact: function (params) {
        return UserMeFactory.one('contact').get(params);
      },
      getFitnessCredits: function () {
        return UserMeFactory.one('fitness_credits').get();
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name product.controller:ProductCtrl
   *
   * @description
   *
   */
  KioskFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('KioskFactory', KioskFactory);

  function KioskFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'kiosk'});
    })
    .service('kiosks');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:Fitness
   *
   * @description
   *
   */
  FitnessFactory.$inject = ["$log", "CustomerManagementFactory", "FitnessEventParticipationService"];
  angular
    .module('kiosk')
    .factory('FitnessFactory', FitnessFactory);

  function FitnessFactory(
    $log,
    CustomerManagementFactory,
    FitnessEventParticipationService
  ) {
    var vm = this;

    // variables
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };

    // methods
    vm.loadCompleteFitnessData = loadCompleteFitnessData;
    vm.loadJournals = loadJournals;
    vm.participation = FitnessEventParticipationService.participation;

    function loadCompleteFitnessData(customer, customerContact) {
      $log.debug('FitnessFactory::loadCompleteFitnessData() -> CustomerContact: ', customerContact);
      return CustomerManagementFactory.getCustomerDetails(customer.id, customerContact.id, ['journals', 'credits', 'eventCategories']).then(function (results) {
        $log.debug('FitnessFactory::loadJournals() -> data: ', results);
        return results;
      });
    }

    function loadJournals(customer, customerContact) {
      $log.debug('FitnessFactory::loadCompleteFitnessData() -> CustomerContact: ', customerContact);
      CustomerManagementFactory.getCustomerDetails(customer.id, customerContact.id, ['journals', 'credits']).then(function (results) {
        $log.debug('FitnessFactory::loadJournals() -> data: ', results);
        return results;
      });
    }
    return vm;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.service:FitnessEventParticipationService
   *
   * @description
   *
   */
  FitnessEventParticipationService.$inject = ["$rootScope", "$log", "EventParticipantFactory", "EventParticipantStatusFactory", "EventParticipantTypeFactory", "EventMultipleRejectParticipantFactory"];
  angular
    .module('kiosk')
    .service('FitnessEventParticipationService', FitnessEventParticipationService);

  function FitnessEventParticipationService(
    $rootScope,
    $log,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    EventParticipantTypeFactory,
    EventMultipleRejectParticipantFactory
  ) {
    var vm = this;

    // variables
    vm.eventInstance = undefined;
    vm.customerContact = undefined;
    vm.myParticipation = undefined;
    vm.participations = [];

    // methods
    vm.cancel = cancel;
    vm.multipleCancel = multipleCancel;
    vm.subscribe = subscribe;
    vm.extraSubscribe = extraSubscribe;

    function cancel(eventInstance) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          // Set Rejected
          return EventParticipantFactory.one(eventInstance.userStatus.id).patch({
            eventParticipantStatus: rejectedStatus.id
          }).then(function () {
            $log.debug('Rejected');
          });
        });
    }

    function multipleCancel(eventInstance) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          // Set Rejected
          return EventMultipleRejectParticipantFactory.one(eventInstance.userStatus.id).patch({
            eventParticipantStatus: rejectedStatus.id
          }).then(function () {
            $log.debug('Multiple Rejected');
          });
        });
    }

    function subscribe(eventInstance, customerContact) {
      var participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);

      if (angular.isDefined(eventInstance.userStatus)) {
        return EventParticipantStatusFactory.getStatusByCode((eventInstance.userStatus.code === 'TENTATIVE') ? 'accepted' : 'pending')
          .then(function (status) {
            // from waitinglist
            participantObject.eventParticipantStatus = status.id;
            // Set Accepted
            return EventParticipantFactory.one(eventInstance.userStatus.id).patch(participantObject);
          });
      }

      return EventParticipantStatusFactory.getStatusByCode('pending')
        .then(function (pendingStatus) {
          return EventParticipantTypeFactory.getTypeByCode('participant')
            .then(function (participantType) {
              participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);
              participantObject.eventParticipantStatus = pendingStatus.id;
              participantObject.eventParticipantType = participantType.id;

              $log.debug('EventParticipationService.subscribe.participantObject', participantObject);

              return EventParticipantFactory.post(participantObject)
                .then(function (newParticipation) {
                  participantObject.myParticipation = newParticipation;

                  if (participantObject.myParticipation.eventParticipantStatus.code === 'TENTATIVE') {
                    return EventParticipantStatusFactory.getStatusByCode('accepted')
                      .then(function (acceptedStatus) {
                        participantObject = {
                          eventParticipantStatus: acceptedStatus.id
                        };
                        // Set Accepted
                        return EventParticipantFactory.one(newParticipation.id).patch(participantObject)
                          .then(function () {
                            $log.debug('Accepted:', newParticipation);
                            participantObject.myParticipation.eventParticipantStatus.code = 'ACCEPTED';
                          }, function () {
                            $log.debug('Not Accepted', newParticipation);
                          });
                      });
                  }

                  $log.debug('Waiting List');
                });
            });
        });
    }

    function extraSubscribe(eventInstance, customerContact) {
      var participantObject = EventParticipantFactory.getParticipationObject(eventInstance, customerContact);
      return EventParticipantTypeFactory.getTypeByCode('participant')
        .then(function (participantType) {
          participantObject.eventParticipantType = participantType.id;
          if (angular.isDefined(eventInstance.userStatus)) {
            return EventParticipantStatusFactory.getStatusByCode('accepted')
              .then(function (status) {
                participantObject.eventParticipantStatus = status.id;
                participantObject.contact = customerContact.contact.id;
                participantObject.event = eventInstance.eventId;
                participantObject.site = $rootScope.environment.siteId;
                return EventParticipantFactory.post(participantObject);
              });
          }
        });
    }
    return vm;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:FitnessCourseFactory
   *
   * @description
   *
   */
  FitnessCourseFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('FitnessCourseFactory', FitnessCourseFactory);

  function FitnessCourseFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'fitnessCourse'});
    })
    .service('fitness/courses');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventParticipantTypeFactory
   *
   * @description
   *
   */
  EventParticipantTypeFactory.$inject = ["Restangular", "RestUtilsFactory", "$log", "_"];
  angular
    .module('kiosk')
    .factory('EventParticipantTypeFactory', EventParticipantTypeFactory);

  function EventParticipantTypeFactory(
    Restangular,
    RestUtilsFactory,
    $log,
    _
  ) {
    var typeService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipantType'});
    })
    .service('events/participants/types');

    return {
      getList: function (params) {
        return RestUtilsFactory.getFullList(typeService, params);
      },
      one: function (fragment) {
        return typeService.one(fragment);
      },
      getTypeByCode: function (code) {
        var status = null,
            params = { limit: 99 };

        code = code.toUpperCase();
        params['filter[]'] = 'code,' + code;
        // journalActions will be cached so this should only cause a delay once
        return typeService.getList(params)
        .then(function (eventParticipantTypes) {
          status = _.find(eventParticipantTypes, function (item) {
            return item.code === code;
          });

          if (status) {
            return status;
          }
          $log.debug('EventParticipantTypeFactory::getStatusByCode() -> Unknown type code ' + code);
          return null;
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventParticipantStatusFactory
   *
   * @description
   *
   */
  EventParticipantStatusFactory.$inject = ["Restangular", "RestUtilsFactory", "$log", "_"];
  angular
    .module('kiosk')
    .factory('EventParticipantStatusFactory', EventParticipantStatusFactory);

  function EventParticipantStatusFactory(
    Restangular,
    RestUtilsFactory,
    $log,
    _
  ) {
    var statusService = Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHttpFields({cache: true});
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipantStatus'});
    })
    .service('event_participants/statuses');

    return {
      getList: function (params) {
        return RestUtilsFactory.getFullList(statusService, params);
      },
      one: function (fragment) {
        return statusService.one(fragment);
      },
      getStatusByCode: function (code) {
        var status = null,
            params = { limit: 99 };

        code = code.toUpperCase();
        params['filter[]'] = 'code,' + code;
        // results will be cached so this should only cause a delay once
        return statusService.getList(params)
        .then(function (eventParticipantStatuses) {
          status = _.find(eventParticipantStatuses, function (item) {
            return item.code === code;
          });

          if (status) {
            return status;
          }
          $log.debug('EventParticipantStatusFactory::getStatusByCode() -> Unknown status code ' + code);
          return null;
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventParticipantFactory
   *
   * @description
   *
   */
  EventParticipantFactory.$inject = ["Restangular", "$log"];
  angular
    .module('kiosk')
    .factory('EventParticipantFactory', EventParticipantFactory);

  function EventParticipantFactory(Restangular, $log) {
    var factory = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipant'});
      })
      .service('event_participants');

    factory.getParticipationObject = function (eventInstance, customerContact) {
      var participation = {};

      $log.debug('new EventParticipationService instance');
      $log.debug('EventParticipationService.eventInstanceId', eventInstance.id);

      participation.eventInstance = eventInstance.id;

      if (angular.isUndefined(eventInstance.userStatus)) {
        participation.contact = customerContact.contact.id;
        participation.site = eventInstance.site;
        participation.event = eventInstance.eventId;
      }

      return participation;
    };

    return factory;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventMultipleRejectParticipantFactory
   *
   * @description
   *
   */
  EventMultipleRejectParticipantFactory.$inject = ["Restangular", "CurrentUserContextFactory", "$log"];
  angular
    .module('kiosk')
    .factory('EventMultipleRejectParticipantFactory', EventMultipleRejectParticipantFactory);

  function EventMultipleRejectParticipantFactory(Restangular, CurrentUserContextFactory, $log) {
    var factory = Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'eventParticipant'});
      })
      .service('event_multiple_participants');

    factory.getParticipationObject = function (eventInstance, customerContact) {
      var participation = {};

      $log.debug('new Multiple EventParticipationService instance');
      $log.debug('EventParticipationService.eventInstanceId', eventInstance.id);

      participation.eventInstance = eventInstance.id;

      if (angular.isUndefined(eventInstance.userStatus)) {
        participation.contact = customerContact.contact.id;
        participation.site = eventInstance.site;
        participation.event = eventInstance.eventId;
      }

      return participation;
    };

    return factory;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EventFactory
   *
   * @description
   *
   */
  EventFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('EventFactory', EventFactory);

  function EventFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'event'});
    })
    .service('events');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:EndpointFactory
   *
   * @description
   *
   */
  EndpointFactory.$inject = ["ENVIRONMENTS"];
  angular
    .module('utils')
    .factory('EndpointFactory', EndpointFactory);

  function EndpointFactory(ENVIRONMENTS) {
    var vm = this;
    vm.getEndpointBySiteId = getEndpointBySiteId;
    vm.getEndpointBySiteName = getEndpointBySiteName;

    function getEndpointBySiteId(siteId) {
      var result = 'default';
      ENVIRONMENTS.forEach(function (site) {
        if (siteId === site.siteId) {
          result = site.baseUrl + '/api/';
        }
      });
      return result;
    }

    function getEndpointBySiteName(siteName) {
      var result = 'default';
      ENVIRONMENTS.forEach(function (site) {
        if (siteName === site.name) {
          result = site.baseUrl + '/api/';
        }
      });
      return result;
    }
    return vm;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:CustomerManagementFactory
   *
   * @description
   *
   */
  CustomerManagementFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('CustomerManagementFactory', CustomerManagementFactory);

  function CustomerManagementFactory(
    Restangular
  ) {
    var detailsService =
      Restangular
      .withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setDefaultHeaders({'x-entity': 'posDetails'});
      })
      .service('services/details')
    ;

    return {
      getCustomerPosDetails: function (customerId, contactId) {
        return this.getCustomerDetails(customerId, contactId, ['journals']);
      },
      getCustomerDetails: function (customerId, contactId, contains, params) {
        var detailsParams = angular.isUndefined(params) ? {} : params;

        if (angular.isDefined(contains)) {
          detailsParams['contains[]'] = [];
          angular.forEach(contains, function (contain) {
            detailsParams['contains[]'].push(contain);
          });
        }

        return detailsService.one('customers').one(customerId).one('contacts').one(contactId).get(detailsParams);
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.factory:CustomerFactory
   *
   * @description
   *
   */
  CustomerFactory.$inject = ["Restangular"];
  angular
    .module('kiosk')
    .factory('CustomerFactory', CustomerFactory);

  function CustomerFactory(Restangular) {
    return Restangular
    .withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({'x-entity': 'customer'});
    })
    .service('customers');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name kiosk.factory:CurrentUserContextFactory
   *
   * @description
   *
   */
  /* @ngInject */
  CurrentUserContextFactory.$inject = ["$cookies", "$log", "$rootScope", "UserMeFactory", "UtilService"];
  angular
    .module('kiosk')
    .factory('CurrentUserContextFactory', CurrentUserContextFactory);

  function CurrentUserContextFactory(
     $cookies,
     $log,
     $rootScope,
     UserMeFactory,
     UtilService
    ) {
    return {
      getSiteId: function () {
        return this.getUserContextCookies().siteId;
      },
      getSiteFacilityId: function () {
        return this.getUserContextCookies().siteFacilityId;
      },
      getUserContextId: function () {
        return this.getUserContextCookies().contextId;
      },
      getUserName: function () {
        return this.getUserContextCookies().userName;
      },
      getSiteName: function () {
        return this.getUserContextCookies().siteName;
      },
      getUserContextCookies: function () {
        return {
          siteId: $cookies.get('currentSiteId'),
          userName: $cookies.get('currentUsername'),
          sitename: $cookies.get('currentSitename'),
          contextId: $cookies.get('currentContextId'),
          siteFacilityId: $cookies.get('siteFacilityId')
        };
      },
      setUserContextCookie: function (userContextId) {
        if (UtilService.isNotEmpty(userContextId)) {
          $cookies.put('userContextId', userContextId);
        }
      },
      isUserContextSelected: function () {
        var cookie = this.getUserContextId();
        return UtilService.isNotEmpty(cookie);
      },
      removeUserContextCookie: function () {
        $log.debug('Removing user context cookie');
        $cookies.remove('userContextId');
      },
      autoSelectUserContext: function (user) {
        var that = this;

        return new Promise(function (resolve, reject) {
          var selectedUserContext = null;

          if (UtilService.isEmpty(user.userContexts)) {
            reject(new Error('user has no user contexts'));
          }
          if (UtilService.isEmpty($rootScope.environment) || UtilService.isEmpty($rootScope.environment.siteId)) {
            reject(new Error('environment has no siteId set'));
          }
          // we set the user context for which siteId matches the selected enviromnent siteId
          angular.forEach(user.userContexts, function (userContext) {
            if (userContext.site.id === $rootScope.environment.siteId) {
              selectedUserContext = userContext;
            }
          });

          if (selectedUserContext === null) {
            reject(new Error('no user context matches the environment'));
          }

          UserMeFactory.one().patch({activeUserContext: selectedUserContext.id}).then(function () {
            that.setUserContextCookie(selectedUserContext.id);
            resolve(selectedUserContext);
          }, function () {
            reject(new Error('unable to patch active user context'));
          });
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name sportoase
   * @description
   *
   */
  config.$inject = ["RestangularProvider", "OAUTH_CONFIG", "PARAMS", "$locationProvider", "datepickerPopupConfig", "toastrConfig", "$modalProvider", "$logProvider"];
  angular.module('sportoase', [
    'angular-repeat-n',
    'angular.filter',
    'angularMoment',
    'auth',
    'base64',
    'duScroll',
    'filters',
    'kiosk',
    'l42y.sprintf',
    'ng-currency',
    'ngAnimate',
    'ngCookies',
    'ngDialog',
    'ngStorage',
    'pascalprecht.translate',
    'restangular',
    'settings',
    'sportoase.config',
    'timer',
    'tmh.dynamicLocale',
    'toastr',
    'toastrNotification',
    'ui.bootstrap',
    'ui.mask',
    'ui.router',
    'ui.select',
    'ui.tree',
    'ui.utils.masks',
    'underscore',
    'utils'
  ]).constant('LOCALES', {
    locales: {
      EN_US: 'locale.en_us',
      NL_BE: 'locale.nl_be',
      FR_FR: 'locale.fr_fr'
    }
  })
    .config(config)
    .config(["tmhDynamicLocaleProvider", function (tmhDynamicLocaleProvider) {
      tmhDynamicLocaleProvider.defaultLocale('nl-be');
    }])
    .run(["$rootScope", "ToastrNotificationService", "$filter", "$compile", "$modalStack", function (
      $rootScope,
      ToastrNotificationService,
      $filter,
      $compile,
      $modalStack
    ) {
      'ngInject';

      $rootScope.$on('$stateChangeStart', function () {
        $rootScope.ui = {
          navigation: true,
          header: true,
          login: false,
          loading: false
        };
        $modalStack.dismissAll('skip');
      });

      // Show errorMsg when a call to backend fails
      $rootScope.$on('showErrorMsgToastrBroadcast', function (event, errorMsg) {
        event.preventDefault();
        void 0;
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.error',
          'app.general-error'
        );
      });
    }]
  )
  .directive('datepickerTimezone', function () {
    var date;
    return {
      restrict: 'A',
      priority: 1,
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        ctrl.$formatters.push(function (value) {
          // console.log('value1: ' + value);
          if (angular.isDate(value) && value.toISOString().length > 0) {
            date = new Date(Date.parse(value));
            date = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
          } else {
            date = null;
          }
          return date;
        });

        ctrl.$parsers.push(function (value) {
          // console.log('value2: ' + value);
          if (angular.isDate(value) && value.toISOString().length > 0) {
            date = new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
          } else {
            date = null;
          }
          return date;
        });
      }
    };
  })
  .directive('dynamicFormErrors', ["UtilsFactory", function (UtilsFactory) {
    return {
      restrict: 'A',
      priority: 1000,
      link: function (scope, element) {
        scope.$on('showFormErrorMsgBroadcast', function (event, errorMsg) {
          var currentSelector,
              errorMessage,
              inputElement,
              dynamicMessagesDiv,
              formGroupElement,
              entity = errorMsg.config.headers['x-entity'];

          event.preventDefault();
            // prevent double/stale error messages by clearing the previous divs if necessary
          dynamicMessagesDiv = element.find('.dynamic-error-messages');
          dynamicMessagesDiv.remove();

          angular.forEach(errorMsg.data.errors.children, function (errors, field) {
              // uppercase first letter of field and concat to entity string
            currentSelector = entity + (field.charAt(0).toUpperCase() + field.slice(1));
            inputElement = element.find('#' + currentSelector);
            formGroupElement = inputElement.parent();

            if (UtilsFactory.isNotEmpty(errors)) {
              if (inputElement.length) {
                formGroupElement.addClass('has-error');

                  // append error messages
                errorMessage = '<div class="dynamic-error-messages">';
                angular.forEach(errors, function (error) {
                  errorMessage += '<div class="error__message_box"><span class="error-message">';
                  errorMessage += error;
                  errorMessage += '</span></div>';
                });
                errorMessage += '</div>';
                formGroupElement.append(errorMessage);
              }
            } else {
              formGroupElement.removeClass('has-error');
            }
          });
        });
      }
    };
  }])
    .directive('formGroup', function () {
      return {
        restrict: 'C',
        priority: 1000,
        link: function (scope, element) {
          if (element.find('*:required').length || element.find('*[required=required]').length) {
            element.addClass('required');
          }
        }
      };
    });

  function config(RestangularProvider, OAUTH_CONFIG, PARAMS, $locationProvider, datepickerPopupConfig, toastrConfig, $modalProvider, $logProvider) {
    RestangularProvider.setBaseUrl(OAUTH_CONFIG.baseUrl + '/api');
    datepickerPopupConfig.datepickerPopup = 'dd/MM/yyyy';
    datepickerPopupConfig.pickTime = false;

    $logProvider.debugEnabled(false);

    if (angular.isDefined(PARAMS)) {
      if (angular.isDefined(PARAMS.debug)) {
        $logProvider.debugEnabled(PARAMS.debug);
      }
    }
    // Extend toast
    angular.extend(toastrConfig, {
      allowHtml: true
    });

    // add a custom response interceptor to deal with non-array (i.e. json object) return values for getList() calls
    // to the backend
    RestangularProvider.addResponseInterceptor(function (data, operation) {
      var extractedData;
      // .. to look for getList operations
      if (operation === 'getList') {
        // .. and handle the data and meta data
        extractedData = data.results;
        extractedData.error = data.error;
        extractedData.count = data.count;
      } else {
        extractedData = data;
      }
      return extractedData;
    });
    $modalProvider.options = {
      backdrop: 'static',
      keyboard: false
    };
    $locationProvider.html5Mode(true);
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserPasswordForgotCtrl
   *
   * @description
   *
   */
  UserSelectActionAccountCtrl.$inject = ["$log", "$modal", "$modalInstance"];
  angular
    .module('sportoase')
    .controller('UserSelectActionAccountCtrl', UserSelectActionAccountCtrl);

  function UserSelectActionAccountCtrl($log, $modal, $modalInstance) {
    var vm = this;
    vm.createNewAccountForNewCustomer = createNewAccountForNewCustomer;
    vm.createNewAccountForExistCustomer = createNewAccountForExistCustomer;
    vm.cancel = cancel;

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function createNewAccountForNewCustomer() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/create_new_account_for_new_customer.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }
    function createNewAccountForExistCustomer() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_create_account.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserPasswordResetCtrl
   *
   * @description
   *
   */
  UserPasswordResetCtrl.$inject = ["$location", "$state", "$stateParams", "$scope", "$rootScope", "$localStorage", "UtilsFactory", "EndpointFactory", "Restangular"];
  angular
    .module('sportoase')
    .controller('UserPasswordResetCtrl', UserPasswordResetCtrl);

  function UserPasswordResetCtrl(
    $location,
    $state,
    $stateParams,
    $scope,
    $rootScope,
    $localStorage,
    UtilsFactory,
    EndpointFactory,
    Restangular
  ) {
    var vm = this;
    vm.errorMessage = null;
    vm.passwordType = 'reset';

    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['user-activation', 'login']
    };
    $rootScope.environment = $localStorage.selectedEnvironment;

    vm.entity = null;
    vm.item = {
      token: $stateParams.token,
      plainPassword: null,
      plainPasswordVerification: null
    };

    vm.backToEnvironments = backToEnvironments;
    vm.isValid = isValid;
    vm.activate = activate;

    $scope.$on('$viewContentLoaded', function () {
      if (UtilsFactory.isNotEmpty($localStorage.redirectLink)) {
        $localStorage.redirectLink = '';
      } else {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk');
      }
    });

    function backToEnvironments() {
      $localStorage.redirectLink = $location.path();
      $state.go('kiosk');
    }

    function isValid() {
      return angular.isDefined(vm.item) &&
        angular.isDefined(vm.item.token) &&
        angular.isString(vm.item.token) &&
        vm.item.token.length &&
        angular.isDefined(vm.item.plainPassword) &&
        angular.isString(vm.item.plainPassword) &&
        vm.item.plainPassword.length &&
        angular.isDefined(vm.item.plainPasswordVerification) &&
        angular.isString(vm.item.plainPasswordVerification) &&
        vm.item.plainPasswordVerification.length &&
        (vm.item.plainPassword === vm.item.plainPasswordVerification);
    }

    function activate() {
      var object = {
        token: vm.item.token,
        plainPassword: vm.item.plainPassword
      };

      vm.errorMessage = null;

      void 0;

      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/password/reset/confirm').customPOST(object).then(function (pwResult) {
        vm.entity = pwResult;
      }, function () {
        vm.errorMessage = 'app.errors.could-not-set-password';
      });
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserPasswordForgotCtrl
   *
   * @description
   *
   */
  UserPasswordForgotCtrl.$inject = ["$modalInstance", "$rootScope", "EndpointFactory", "Restangular"];
  angular
    .module('sportoase')
    .controller('UserPasswordForgotCtrl', UserPasswordForgotCtrl);

  function UserPasswordForgotCtrl(
    $modalInstance,
    $rootScope,
    EndpointFactory,
    Restangular
  ) {
    var vm = this;

    /* ----- PARAMS ----- */
    vm.email = '';
    vm.errorMessage = null;
    vm.successMessage = null;
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.cancel = cancel;
    vm.sendResetPasswordMail = sendResetPasswordMail;
    /* ----- END FUNCTIONS ----- */

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function sendResetPasswordMail() {
      var object = {
        email: vm.email
      };
      vm.errorMessage = null;
      vm.successMessage = null;

      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/password/reset').customPOST(object).then(function () {
        // When success
        vm.successMessage = 'app.success.reset-password-mail-sended';
      }, function () {
        // When error
        vm.errorMessage = 'app.errors.could-not-find-account-with-email';
      });
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserPasswordForgotCtrl
   *
   * @description
   *
   */
  UserCreateAccountCtrl.$inject = ["$document", "$filter", "$rootScope", "$modalInstance", "EndpointFactory", "Restangular", "$translate", "email"];
  angular
    .module('sportoase')
    .controller('UserCreateAccountCtrl', UserCreateAccountCtrl);

  function UserCreateAccountCtrl(
    $document,
    $filter,
    $rootScope,
    $modalInstance,
    EndpointFactory,
    Restangular,
    $translate,
    email
  ) {
    var vm = this;
    vm.ifSuccess = false;
    vm.email = email ? email : null;
    vm.birthday = '';
    vm.username = '';
    vm.firstName = '';
    vm.lastName = '';
    vm.phoneNumber = '';
    vm.street = '';
    vm.streetNumber = '';
    vm.postalCode = '';
    vm.city = '';
    vm.errorMessage = null;
    vm.errorMessageContent = null;
    vm.successMessage = null;
    vm.site = null;
    vm.datePickerIsOpened = false;
    vm.openDatePicker = openDatePicker;
    vm.cancel = cancel;
    vm.createNewAccount = createNewAccount;
    vm.createNewAccountForExistCustomer = createNewAccountForExistCustomer;
    vm.createNewAccountWithData = createNewAccountWithData;

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function openDatePicker() {
      vm.datePickerIsOpened = true;
    }

    function removeTimezone(dateWithTime) {
      return $filter('date')(dateWithTime, 'yyyy-MM-dd');
    }

    function createNewAccount() {
      var object = {
        email: vm.email,
        birthDate: removeTimezone(vm.birthday),
        username: 'newUser',
        phoneNumber: vm.phoneNumber,
        firstName: vm.firstName,
        lastName: vm.lastName,
        site: $rootScope.environment.siteId,
        locale: getLocaleFromTranslate()
      };
      vm.errorMessage = null;
      vm.successMessage = null;
      disableSubmitButton(true);
      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-with-customer').customPOST(object).then(function () {
        vm.successMessage = 'app.success.user-account-was-created';
        vm.ifSuccess = true;
        disableSubmitButton(false);
      }, function (result) {
        vm.errorMessage = 'app.errors.user-account-was-not-created ';
        vm.errorMessageContent = result.data.message;
        disableSubmitButton(false);
      });
    }

    function createNewAccountWithData() {
      var object = {
        email: vm.email,
        birthDate: removeTimezone(vm.birthday),
        username: 'newUser',
        phoneNumber: vm.phoneNumber,
        firstName: vm.firstName,
        lastName: vm.lastName,
        site: $rootScope.environment.siteId,
        locale: getLocaleFromTranslate(),
        street: vm.street,
        streetNumber: vm.streetNumber,
        postalCode: vm.postalCode,
        city: vm.city,
        countryCode: 'BE'
      };
      vm.errorMessage = null;
      vm.successMessage = null;
      disableSubmitButton(true);
      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-with-customer-or-without-customer').customPOST(object).then(function () {
        vm.successMessage = 'app.success.user-account-was-created';
        vm.ifSuccess = true;
        disableSubmitButton(false);
      }, function (result) {
        vm.errorMessage = 'app.errors.user-account-was-not-created ';
        vm.errorMessageContent = result.data.message;
        disableSubmitButton(false);
      });
    }

    function createNewAccountForExistCustomer() {
      var object = {
        email: vm.email,
        birthDate: removeTimezone(vm.birthday),
        username: 'newUser',
        site: $rootScope.environment.siteId,
        locale: getLocaleFromTranslate()
      };
      vm.errorMessage = null;
      vm.successMessage = null;
      disableSubmitButton(true);
      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register').customPOST(object).then(function () {
        vm.successMessage = 'app.success.user-account-was-created';
        vm.ifSuccess = true;
        disableSubmitButton(false);
      }, function (result) {
        vm.errorMessage = 'app.errors.user-account-was-not-created ';
        vm.errorMessageContent = result.data.message;
        disableSubmitButton(false);
      });
    }

    function getLocaleFromTranslate() {
      var result = $translate.use().split('_')[0].toLowerCase();
      return result;
    }

    function disableSubmitButton(state) {
      angular.element($document[0].getElementById('create-new-account-submit-button'))[0].disabled = state;
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:UserActivationCtrl
   *
   * @description
   *
   */
  UserActivationCtrl.$inject = ["$location", "$state", "$stateParams", "$scope", "$rootScope", "$localStorage", "UtilsFactory", "EndpointFactory", "Restangular"];
  angular
    .module('sportoase')
    .controller('UserActivationCtrl', UserActivationCtrl);

  function UserActivationCtrl(
    $location,
    $state,
    $stateParams,
    $scope,
    $rootScope,
    $localStorage,
    UtilsFactory,
    EndpointFactory,
    Restangular
  ) {
    var vm = this;
    vm.errorMessage = null;
    vm.passwordType = 'activation';

    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['user-activation', 'login']
    };
    $rootScope.environment = $localStorage.selectedEnvironment;

    vm.entity = null;
    vm.item = {
      token: $stateParams.token,
      plainPassword: null,
      plainPasswordVerification: null
    };

    vm.backToEnvironments = backToEnvironments;
    vm.isValid = isValid;
    vm.activate = activate;

    $scope.$on('$viewContentLoaded', function () {
      if (UtilsFactory.isNotEmpty($localStorage.redirectLink)) {
        $localStorage.redirectLink = '';
      } else {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk.choose_site');
      }
    });

    function backToEnvironments() {
      $localStorage.redirectLink = $location.path();
      $state.go('kiosk.choose_site');
    }

    function isValid() {
      return angular.isDefined(vm.item) &&
        angular.isDefined(vm.item.token) &&
        angular.isString(vm.item.token) &&
        vm.item.token.length &&
        angular.isDefined(vm.item.plainPassword) &&
        angular.isString(vm.item.plainPassword) &&
        vm.item.plainPassword.length &&
        angular.isDefined(vm.item.plainPasswordVerification) &&
        angular.isString(vm.item.plainPasswordVerification) &&
        vm.item.plainPasswordVerification.length &&
        (vm.item.plainPassword === vm.item.plainPasswordVerification);
    }

    function activate() {
      var object = {
        token: vm.item.token
      };

      vm.errorMessage = null;

      Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/activate').customPOST(object).then(function (actResult) {
        vm.item.token = actResult.token;
        object.token = vm.item.token;
        object.plainPassword = vm.item.plainPassword;

        Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/password/reset/confirm').customPOST(object).then(function (pwResult) {
          vm.entity = pwResult;
        }, function () {
          vm.errorMessage = 'app.errors.could-not-set-password';
        });
      }, function () {
        vm.errorMessage = 'app.errors.could-not-activate-account';
      });
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sportoase.controller:MultipleSubscribeCtrl
   *
   * @description
   *
   */
  MultipleSubscribeCtrl.$inject = ["$modalInstance"];
  angular
    .module('sportoase')
    .controller('MultipleSubscribeCtrl', MultipleSubscribeCtrl);

  function MultipleSubscribeCtrl($modalInstance) {
    var vm = this;
    vm.count = 0;
    vm.cancel = cancel;
    vm.result = 0;
    vm.sendCount = sendCount;
    vm.setCount = setCount;
    vm.error = null;
    vm.availableCreditsCounts = setAvailableCredits();

    function cancel() {
      $modalInstance.dismiss('cancel');
    }

    function setAvailableCredits() {
      var result = [], i = 1;
      for (i; i <= $modalInstance.availableCredits; i++) {
        if (i > 9) {
          break;
        }
        result.push({count: i});
      }
      return result;
    }

    function setCount(number) {
      vm.count = number;
    }

    function sendCount() {
      if (vm.count > $modalInstance.availableCredits) {
        vm.error = 'insufficient funds';
      } else {
        $modalInstance.count = vm.count;
        $modalInstance.close();
      }
    }
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name kiosk.controller:KioskCtrl
   *
   * @description
   *
   */
  KioskCtrl.$inject = ["$q", "$cookies", "$filter", "$localStorage", "$location", "$log", "$modal", "moment", "$rootScope", "$scope", "$state", "$stateParams", "$timeout", "$interval", "$window", "ENVIRONMENTS", "CurrentUserContextFactory", "EndpointFactory", "EventFactory", "EventParticipantFactory", "EventParticipantStatusFactory", "FitnessFactory", "FitnessEventParticipationService", "LocaleService", "MyEntitiesFactory", "OAuth", "OAuthToken", "ProductFactory", "PromocodeFactory", "Restangular", "RestUtilsFactory", "SaleFactory", "SettingsService", "ShoppingCartService", "ToastrNotificationService", "UserMeFactory", "UtilsFactory", "$translate", "PARAMS"];
  angular
    .module('kiosk')
    .controller('KioskCtrl', KioskCtrl);

  function KioskCtrl(
    $q,
    $cookies,
    $filter,
    $localStorage,
    $location,
    $log,
    $modal,
    moment,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $interval,
    $window,
    ENVIRONMENTS,
    CurrentUserContextFactory,
    EndpointFactory,
    EventFactory,
    EventParticipantFactory,
    EventParticipantStatusFactory,
    FitnessFactory,
    FitnessEventParticipationService,
    LocaleService,
    MyEntitiesFactory,
    OAuth,
    OAuthToken,
    ProductFactory,
    PromocodeFactory,
    Restangular,
    RestUtilsFactory,
    SaleFactory,
    SettingsService,
    ShoppingCartService,
    ToastrNotificationService,
    UserMeFactory,
    UtilsFactory,
    $translate,
    PARAMS
  ) {
    var vm = this;
    vm.components = [
      {
        name: 'RESERVATIONS',
        isVisible: true,
        init: function () {}
      },
      {
        name: 'MYRESERVATIONS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'MYJOURNALS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'HISTORY',
        isVisible: false,
        init: initHistory
      },
      {
        name: 'INDIVIDUALTICKETS',
        isVisible: false,
        init: function () {}
      },
      {
        name: 'SHOP',
        isVisible: false,
        init: initWebshop
      },
      {
        name: 'CART',
        isVisible: false,
        init: initWebshopCart
      }
    ];

    /* ----- PARAMS ----- */
    vm.multipleSubscribeActionIsOpen = false;
    vm.eventsForDispayStatusMessage = [];
    vm.failedLogin = false;
    vm.selectUserContextFailed = false;
    vm.contactHasCredits = false;
    vm.contactFitnessCredits = null;
    vm.calendar = {};
    vm.categories = {};
    vm.customer = {
      id: $stateParams.Id
    };
    vm.customerContact = {};
    vm.fitnessEvents = [];
    vm.fitnessEventsAreLoading = true;
    vm.participationHistory = [];
    vm.journalModalInstance = null;
    vm.journals = {};
    vm.journalTypes = {};
    vm.products = [];
    vm.cartItems = {};
    vm.cartTotals = 0;
    vm.cartIndividualItems = {};
    vm.cartIndividualTotals = 0;
    vm.guest = {};
    vm.selectedDate = false;
    vm.selectedCategory = false;
    vm.showingJournalModal = false;
    vm.now = {
      date: new Date(),
      seconds: Math.round(new Date().getTime() / 1000)
    };
    vm.calendarIsVisible = false;
    vm.historyIsVisible = false;
    vm.webshopIsVisible = false;
    vm.webshopCartIsVisible = false;
    vm.webshopSaleIsLoading = false;

    vm.filterPosition = [];

    vm.environments = ENVIRONMENTS.filter(function (e) {
      if (angular.isUndefined(e.visibleFrom)) {
        return true;
      }
      return (new Date(e.visibleFrom) <= new Date());
    });
    vm.currentSitesData = [];

    vm.config = [];
    vm.selectedBackend = 0;
    // set root UI variables
    $rootScope.ui = {
      navigation: false,
      header: false,
      bodyClasses: ['kiosk']
    };
    vm.registeredEvents = 0;
    vm.availableCredits = 0;

    vm.passwordModalInstance = null;
    vm.passwordModalIsOpen = false;
    vm.newAccountModalInstance = null;
    vm.newAccountIsOpen = false;
    vm.selectActionIsOpen = false;
    vm.renewJournalModalInstance = null;
    vm.renewJournalIsOpen = false;
    vm.currentLocale = $translate.use();
    vm.defaultMaxProductsNumber = 9;
    vm.maxProductsNumber = vm.defaultMaxProductsNumber;
    vm.individualTicketsCount = 0;
    vm.myFitnessEvents = [];
    vm.editableFitnessEvents = [];
    vm.individualTicketsDatePickerIsOpened = false;
    vm.individualTicketsDatePickerMinDate = new Date();
    vm.eventsDate = new Date();
    vm.eventsDatePickerIsOpened = false;
    vm.currentDate = new Date();
    vm.eventsDatePickerMinDate = new Date(vm.currentDate.toLocaleString('en-US', { timeZone: 'Europe/Brussels' }));
    vm.currentPurchaseStep = 1;
    vm.purchaseProducts = [];
    vm.activePurchaseResidentButton = null;
    vm.individualTicketsDate = null;
    vm.eventsForIndividualTickets = [];
    vm.activityTypeForIndividualEvents = 'Recreatiebad';
    vm.selectedEventInstanceForIndividualTickets = null;
    vm.selectedEventInstanceForEdit = null;
    vm.noEventsWithAvailablePlacesForIndividual = false;
    vm.searchEventsDone = false;
    vm.promocodeDiscount = null;
    vm.cartDiscount = null;
    vm.cartIndividualTotalDiscount = null;
    vm.cartTotalDiscount = null;
    vm.promocodeValid = false;
    vm.promocodeChecked = false;
    vm.needReloadEvents = false;
    vm.waitForCustomerInfo = true;
    vm.displayLoginScreen = true;
    /* ----- END PARAMS ----- */

    /* ----- FUNCTIONS ----- */
    vm.back = back;
    vm.changeStatus = changeStatus;
    vm.createNewAccount = createNewAccount;
    vm.dateToSeconds = dateToSeconds;
    vm.enableWebshop = false;
    vm.getAvailablePlacesText = getAvailablePlacesText;
    vm.getButtonText = getButtonText;
    vm.isDisplayAttentionMessage = isDisplayAttentionMessage;
    vm.isVisible = isVisible;
    vm.isComponentVisible = isComponentVisible;
    vm.loadCompleteFitnessData = loadCompleteFitnessData;
    vm.loadConfig = loadConfig;
    vm.loadCustomerData = loadCustomerData;
    vm.loadFitnessCredits = loadFitnessCredits;
    vm.loadFitnessEvents = loadFitnessEvents;
    vm.login = login;
    vm.registerAsGuest = registerAsGuest;
    vm.loginAsGuest = loginAsGuest;
    vm.logout = logout;
    vm.passwordForgot = passwordForgot;
    vm.returnOfPasswordModalInstance = returnOfPasswordModalInstance;
    vm.selectAction = selectAction;
    vm.selectCategory = selectCategory;
    vm.selectDate = selectDate;
    vm.showButton = showButton;
    vm.showCalendar = showCalendar;
    vm.showComponent = showComponent;
    vm.subscribeButtonDisabled = subscribeButtonDisabled;
    vm.initWebshopCart = initWebshopCart;
    vm.initHistory = initHistory;
    vm.initWebshop = initWebshop;
    vm.updateCategories = updateCategories;
    vm.checkForOneTimeDispkayEvent = checkForOneTimeDispkayEvent;
    vm.changeLocale = changeLocale;
    vm.getProductByTypeLabel = getProductByTypeLabel;
    vm.filterByEntrances = filterByEntrances;
    vm.allProducts = null;
    vm.getRejects = getRejects;
    vm.rejectionArray = [];
    vm.cancelParticipation = cancelParticipation;
    vm.getProductList = getProductList;
    vm.nextPurchaseStep = nextPurchaseStep;
    vm.previousPurchaseStep = previousPurchaseStep;
    vm.getProductListByCategories = getProductListByCategories;
    vm.getEventsForIndividualTickets = getEventsForIndividualTickets;
    vm.selectEventInstance = selectEventInstance;
    vm.editEventInstance = editEventInstance;
    vm.changeEventInstance = changeEventInstance;
    vm.checkPromocode = checkPromocode;
    vm.promocodeValue = '';
    vm.setPromocode = setPromocode;
    vm.getEventsForCustomer = getEventsForCustomer;
    vm.getReservationsForCustomer = getReservationsForCustomer;
    vm.filterViaActivity = filterViaActivity;
    vm.openNav = 'width: 0px; margin-left: 0px';
    vm.openSidebar = openSidebar;
    vm.actionSelectionModalInstance = null;
    vm.actionSelectionIsOpen = false;
    vm.displayDialog = displayDialog;
    vm.closeDialog = closeDialog;
    vm.guestEventUpdated = $rootScope.guestEventUpdated;
    vm.loadBackendConfig = loadBackendConfig;
    vm.onlineReservationDisabled = angular.isDefined(PARAMS.settings) && angular.isDefined(PARAMS.settings.onlineReservationDisabled) ? PARAMS.settings.onlineReservationDisabled : false;

    /* ----- END FUNCTIONS ----- */

    /* ----- ON INIT ----- */
    //if access to login adn site is disabled then return back to main page
    if ($state.current.name === 'kiosk.login' && vm.onlineReservationDisabled) {
      $state.go('kiosk');
    }

    if ($state.current.name === 'kiosk.customer' || $state.current.name === 'kiosk.guest') {
      RestUtilsFactory.getFullList(EventParticipantStatusFactory, {limit: 99, sort: 'label,asc'}).then(function (resultStatuses) {
        vm.participantStatuses = resultStatuses;
      });
      EventFactory.one('participants').getList('types', {limit: 99, sort: 'label,asc'}).then(function (resultTypes) {
        vm.participantTypes = resultTypes;
      });
      SettingsService.reloadSettings().then(function () {
        void 0;
        vm.enableWebshop = angular.isDefined($localStorage.enableWebshop) || SettingsService.get('settings.enableWebshop', false);
      });

      // check if webshop sale was completed
      if ($location.search().sale_id) {
        vm.webshopSaleIsLoading = true;

        SaleFactory.checkMolliePayment($location.search().sale_id).then(function () {
          SaleFactory.isSaleComplete($location.search().sale_id).then(function (res) {
            vm.webshopSaleIsLoading = false;
            if (!res.completed) {
              ToastrNotificationService.showTranslatedNotification('warning', 'kiosk.webshop', 'product.buy_error');
            } else if (res.guest) {
              // guest users should see a thank you page after a successfull payment
              vm.currentPurchaseStep = 5;
            } else {
              ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', (res.withReservation ? 'product.buy_with_reservation_success' : 'product.buy_success'));
            }
          });
        });
      }

      vm.loadCustomerData()
        .then(function () {
          vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
            if (cc.firstName === 'Proefbeurt') {
              void 0;
              vm.enableWebshop = true;
            }
            return cc.customerContactType.code === 'USER';
          })[0];
          vm.loadCompleteFitnessData();
        });

      $interval(function () {
        UserMeFactory.one().get().then(function () {
          $log.debug('Reloaded the token !!!');
        });
      }, 600000);
    }

    if ($state.current.name === 'kiosk.guest_edit') {
      vm.loadCustomerData().then(function () {
        vm.customerContact = $filter('filter')(vm.customer.customerContacts, function (cc) {
          return cc.customerContactType.code === 'USER';
        })[0];
        getEditableReservationsForCustomer();
      });
    }

    if ($state.current.name === 'kiosk.guest_login') {
      setEnvironmentFromLocalStorage(true).then(function () {
        if (angular.isUndefined($rootScope.loggingInAsGuest)) {
          $rootScope.loggingInAsGuest = true;
          return vm.loginAsGuest();
        }
      }, function () {
        $localStorage.redirectLink = $location.path();
        $state.go('kiosk.choose_site');
      });
    }

    /* ----- END ON INIT ----- */

    $scope.$on('$viewContentLoaded', function () {
      setEnvironmentFromLocalStorage();
    });

    function setEnvironmentFromLocalStorage(returnPromise) {
      if ($localStorage.selectedBackend || (!$localStorage.selectedBackend && $localStorage.selectedBackend === 0)) {
        return $timeout(function () {
          $rootScope.environment = vm.environments[$localStorage.selectedBackend];
        });
      }
      if (angular.isDefined(returnPromise)) {
        return Promise.reject(new Error('no selected backend in localStorage'));
      }
    }

    function filterViaActivity(activityName) {
      if (activityName === 'swimming') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasSwimming;
        });
      }

      if (activityName === 'fitness') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasFitness;
        });
      }

      if (activityName === 'group lessons') {
        vm.currentSitesData = vm.environments.filter(function (element) {
          return element.hasGroupLessons;
        });
      }

      vm.filterPosition = vm.currentSitesData.map(function (element, index) {
        return {
          position: element.mapPosition,
          name: element.name,
          city: element.city,
          image: element.image,
          index: index
        };
      });
    }

    function createNewAccount() {
      if (vm.newAccountIsOpen) {
        return;
      }
      vm.newAccountModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_create_account.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl'
      });
      vm.newAccountIsOpen = true;

      vm.newAccountModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.newAccountIsOpen = false;
      });
    }

    function selectAction() {
      if (vm.selectActionIsOpen) {
        return;
      }
      vm.selectActionModalInstance = $modal.open({
        // templateUrl: 'kiosk/views/user_select_action.modal.tpl.html',
        // controller: 'UserSelectActionAccountCtrl',
        // controllerAs: 'userSelectActionAccountCtrl'
        templateUrl: 'kiosk/views/create_new_account_for_new_customer.modal.tpl.html',
        controller: 'UserCreateAccountCtrl',
        controllerAs: 'userCreateAccountCtrl',
        resolve: {
          email: function () {
            return angular.isDefined(vm.credentials) && angular.isDefined(vm.credentials.email) ? vm.credentials.email : null;
          }
        }
      });
      vm.selectActionIsOpen = true;

      vm.selectActionModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.selectActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.selectActionIsOpen = false;
      });
    }

    function passwordForgot() {
      // Show modal
      // Check if the modal view is already open
      if (vm.passwordModalIsOpen) {
        return;
      }
      // Open a modal view from specifi c template and controller. A customer object and customerContact object are added to the controller
      vm.passwordModalInstance = $modal.open({
        templateUrl: 'kiosk/views/user_password_forgot.modal.tpl.html',
        controller: 'UserPasswordForgotCtrl',
        controllerAs: 'userPasswordForgotCtrl'
      });
      vm.passwordModalIsOpen = true;
      vm.returnOfPasswordModalInstance();
    }

    function returnOfPasswordModalInstance() {
      vm.passwordModalInstance.result.then(function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.passwordModalIsOpen = false;
      });
    }

    function getRejects(eventInstance) {
      var rejectionArray = [];
      eventInstance.eventParticipants.forEach(function (participant) {
        if (participant.eventParticipantStatus.code === 'ACCEPTED' && participant.eventParticipantType.code === 'PARTICIPANT' && vm.customerContact.contact.id === participant.contact.id) {
          rejectionArray.push(participant);
          // console.log(participant);
        }
      });
      // console.log(eventInstance);
      return rejectionArray;
    }

    function cancelParticipation(eventParticipant) {
      return EventParticipantStatusFactory.getStatusByCode('rejected')
        .then(function (rejectedStatus) {
          var deferred = $q.defer(),
              participantObject = {
                eventParticipantStatus: rejectedStatus.id
              };
          // Set Rejected
          EventParticipantFactory.one(eventParticipant.id).patch(participantObject).then(function () {
            //reload data
            vm.loadCompleteFitnessData();
          });
          return deferred.promise;
        });
    }

    function loadCompleteFitnessData() {
      vm.availableCredits = null;
      vm.registeredEvents = 0;
      vm.fitnessEventsAreLoading = true;
      return FitnessFactory.loadCompleteFitnessData(vm.customer, vm.customerContact)
        .then(function (fitnessData) {
          $log.debug('KioskCtrl::loadFitnessEvents() -> FitnessData:', fitnessData);
          vm.contactFitnessCredits = fitnessData.credits;

          vm.journals = {};
          vm.journalTypes = {};
          angular.forEach(fitnessData.activeJournals, function (journal) {
            vm.journals[journal.id] = journal;
            if (angular.isDefined(vm.journalTypes[journal.journalType.id])) {
              vm.journalTypes[journal.journalType.id].push(journal.id);
            } else {
              vm.journalTypes[journal.journalType.id] = [journal.id];
            }
          });
          vm.waitForCustomerInfo = false;
          if (vm.contactFitnessCredits > 0) {
            vm.contactHasCredits = true;
          }

          vm.getReservationsForCustomer();
          if (vm.needReloadEvents) {
            return vm.getEventsForCustomer();
          }
        });
    }

    function changeStatus(status, eventInstance) {
      $log.debug('status: ', status);
      $log.debug('Kiosk: event: ', eventInstance.label);
      switch (status) {
        case 'cancel':
        case 'cancel-waitinglist':
          return FitnessEventParticipationService.multipleCancel(eventInstance).then(function () {
            $log.debug('Kiosk: event participation cancelled');
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation multiple cancel failed');
          });
        // return FitnessEventParticipationService.cancel(eventInstance).then(function () {
        //   $log.debug('Kiosk: event participation cancelled');
        //   return vm.loadCompleteFitnessData();
        // }, function () {
        //   $log.debug('Kiosk: event participation cancel failed');
        // });
        case 'subscribe':
          if (checkInstaceForMultipleSubscribing(eventInstance)) {
            return multipleSubscribe(eventInstance, vm.customerContact);
          }
          //extract to method single subscribe
          return FitnessEventParticipationService.subscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        case 'extra-subscribe':
          return FitnessEventParticipationService.extraSubscribe(eventInstance, vm.customerContact).then(function () {
            $log.debug('Kiosk: event participation subscription');
            vm.eventsForDispayStatusMessage.push(eventInstance.id);
            return vm.loadCompleteFitnessData();
          }, function () {
            $log.debug('Kiosk: event participation subscription failed');
            return vm.loadCompleteFitnessData();
          });
        default:
          return Promise.reject();
      }
    }

    function checkInstaceForMultipleSubscribing(eventInstance) {
      var result = false;
      angular.forEach(vm.journals, function (journal) {
        if (journal.journalType.multipleConsumptions) {
          eventInstance.eligibleJournalTypeIds.forEach(function (element) {
            if (journal.journalType.id === element && journal.journalType.multipleConsumptions === true) {
              result = true;
            }
          });
        }
      });
      return result;
    }

    function checkForOneTimeDispkayEvent(idEvent) {
      if (vm.eventsForDispayStatusMessage.includes(idEvent)) {
        return true;
      }
      return false;
    }
    function multipleSubscribe(eventInstance, customerContact) {
      var i = 0, promicesOfSubscribes = [];
      if (vm.multipleSubscribeActionIsOpen) {
        return;
      }
      vm.multipleSubscribeInstance = $modal.open({
        templateUrl: 'kiosk/views/multiple_subscribe.modal.tpl.html',
        controller: 'MultipleSubscribeCtrl',
        controllerAs: 'multipleSubscribeCtrl'
      });
      vm.multipleSubscribeInstance.availableCredits = vm.contactFitnessCredits;
      vm.multipleSubscribeActionIsOpen = true;
      vm.multipleSubscribeInstance.result.then(function (returnValue) {
        for (i = 0; i < vm.multipleSubscribeInstance.count; i++) {
          void 0;
          promicesOfSubscribes.push(new Promise(function (res) {
            return res(FitnessEventParticipationService.subscribe(eventInstance, customerContact));
          }));
        }
        Promise.all(promicesOfSubscribes).then(function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          return vm.loadCompleteFitnessData();
        }, function () {
          $log.debug('reason of closing: ' + returnValue);
          vm.multipleSubscribeActionIsOpen = false;
          return vm.loadCompleteFitnessData();
        });

        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      }, function (returnValue) {
        $log.debug('reason of closing: ' + returnValue);
        vm.multipleSubscribeActionIsOpen = false;
      });
      // return vm.loadCompleteFitnessData();
    }

    function dateToSeconds(dateString) {
      return moment(dateString).unix();
    }

    function isVisible(eventInstance) {
      return eventInstance.eventCategories.filter(function (eventCategory) {
        return eventCategory.code === vm.selectedCategory || vm.selectedCategory === false;
      }).length;
    }

    function loadCustomerData() {
      return MyEntitiesFactory.getCustomer()
        .then(function (customer) {
          vm.customer = customer;
        });
    }

    function loadFitnessCredits() {
      return MyEntitiesFactory
        .getFitnessCredits()
        .then(function (resultCredits) {
          $log.debug('fitness credits', resultCredits);
          if (!angular.isArray(resultCredits)) {
            vm.contactFitnessCredits = resultCredits.credits;
            vm.contactHasCredits = true;
          } else {
            vm.contactHasCredits = false;
          }
        });
    }

    function loadFitnessEvents() {
      return FitnessFactory.loadFitnessEvents(vm.journals, vm.customerContact).then(function (results) {
        $log.debug('FitnessEvents: ', results);
        vm.fitnessEvents = results.events;
      });
    }

    function login() {
      vm.failedLogin = false;
      return OAuth.getAccessToken(vm.credentials)
        .then(function (response) {
          $log.debug('KIOSK: Form authentication successful', response);

          return fetchUser().then(function (customer) {
            vm.displayLoginScreen = false;
            $state.go('kiosk.customer', {customerId: customer.id});
          });
        }, function (errorResponse) {
          vm.failedLogin = true;
          $log.debug('KIOSK: Form authentication failed' + errorResponse);
        });
    }

    function registerAsGuest() {
      return vm.logout(true).finally(function () {
        return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/register-and-login-as-guest')
          .customPOST({
            site: $rootScope.environment.siteId,
            locale: $translate.use().split('_')[0].toLowerCase()
          }).then(function (response) {
            $log.debug('KIOSK: Guest authentication successful', response);

            OAuthToken.setToken(response);

            return fetchUser().then(function (customer) {
              $state.go('kiosk.guest', {customerId: customer.id});
            });
          }, function (errorResponse) {
            $log.debug('KIOSK: Guest authentication failed' + errorResponse);
          });
      });
    }

    function loginAsGuest() {
      return vm.logout(true).finally(function () {
        return Restangular.oneUrl('endpoint', EndpointFactory.getEndpointBySiteName($rootScope.environment.name) + 'open/users/login-as-guest')
          .customPOST({
            token: $stateParams.token
          }).then(function (response) {
            $log.debug('KIOSK: Guest login successful', response);

            OAuthToken.setToken(response);

            return fetchUser().then(function (customer) {
              $state.go('kiosk.guest_edit', {customerId: customer.id});
            });
          }, function (errorResponse) {
            $log.debug('KIOSK: Guest login failed' + errorResponse);
          });
      });
    }

    function fetchUser() {
      return UserMeFactory.one().get()
        .then(function (user) {
          $log.debug('KIOSK: Fetched user' + user);
          LocaleService.syncBackendLocale(user.locale);
          return CurrentUserContextFactory.autoSelectUserContext(user)
            .then(function () {
              return MyEntitiesFactory.getCustomer()
                .then(function (customer) {
                  if (customer) {
                    $log.debug('KIOSK: Fetched customer linked to user' + customer);
                    return Promise.resolve(customer);
                  }
                  vm.failedLogin = true;
                  $log.debug('KIOSK: No customer found linked to user' + user);
                  return Promise.reject();
                }, function (errorResponse) {
                  vm.failedLogin = true;
                  $log.debug('KIOSK: Failed to fetch customer linked to user' + errorResponse);
                });
            }, function (error) {
              vm.failedLogin = true;
              vm.selectUserContextFailed = true;
              $log.debug('KIOSK: ' + error);
              OAuth.revokeToken();
            });
        }, function (errorResponse) {
          vm.failedLogin = true;
          $log.debug('KIOSK: Failed to fetch user' + errorResponse);
        });
    }

    function logout(noRedirect) {
      var token = OAuthToken.getRefreshToken() ? OAuthToken.getRefreshToken() : OAuthToken.getAccessToken();
      vm.customerContact = {};
      vm.customer = {};
      vm.contactHasCredits = false;
      vm.contactFitnessCredits = null;
      angular.forEach($cookies, function (chip) {
        $cookies.remove(chip);
      });
      if (token) {
        return OAuth.revokeToken().then(function () {
          if (!noRedirect) {
            $state.go('kiosk');
          }
        });
      }

      return Promise.resolve();
    }

    function selectDate(date) {
      vm.selectedDate = date;
      vm.showCalendar(false);
    }

    function selectCategory(category) {
      vm.selectedCategory = vm.selectedCategory === category.code ? false : category.code;
    }

    function getButtonText(eventInstance, returnClass) {
      var valueToReturn = '';
      returnClass = angular.isUndefined(returnClass) ? false : returnClass;

      if (!eventInstance.numberOfVacancies) {
        if (eventInstance.userStatus && eventInstance.userStatus.code === 'TENTATIVE') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.confirm_tentative_status';
        } else {
          valueToReturn = returnClass ? 'btn-warning' : 'app.subscribe_to_waiting_list';
        }
      } else {
        $log.debug('Kiosk: has places available. Return correct text.');
        if (eventInstance.userStatus && eventInstance.userStatus.code === 'TENTATIVE') {
          valueToReturn = returnClass ? 'btn-primary' : 'app.confirm_tentative_status';
        } else {
          valueToReturn = returnClass ? 'btn-primary' : 'app.subscribe';
        }
      }
      return valueToReturn;
    }

    function getAvailablePlacesText(eventInstance) {
      var valueToReturn = '';
      if (!eventInstance.numberOfVacancies) {
        valueToReturn = 'kiosk.waiting_list';
      } else {
        $log.debug('Kiosk: has places available. Return correct text.');
        if (eventInstance.numberOfVacancies > 1) {
          valueToReturn = 'kiosk.places_available';
        } else {
          valueToReturn = 'kiosk.place_available';
        }
      }
      return valueToReturn;
    }

    function isComponentVisible(component) {
      var comp = vm.components.filter(function (c) {
        return c.name === component;
      });
      return comp[0].isVisible;
    }

    function showComponent(component) {
      vm.components.filter(function (c) {
        return c.name !== component;
      }).forEach(function (c) {
        c.isVisible = false;
      });

      vm.components.filter(function (c) {
        return c.name === component;
      }).forEach(function (c) {
        c.isVisible = true;
        c.init();
      });
    }

    function showButton(action, eventInstance) {
      var isTentative = false,
          isAccepted = false,
          isPending = false,
          isPendingWithReservationSale = false,
          participates = angular.isDefined(eventInstance.userStatus),
          attends = angular.isDefined(eventInstance.userStatus) &&
          angular.isDefined(eventInstance.userStatus.code) &&
          eventInstance.userStatus.code === 'ATTENDED';

      if (eventInstance.userStatus) {
        isTentative = eventInstance.userStatus.code === 'TENTATIVE';
        isAccepted = eventInstance.userStatus.code === 'ACCEPTED';
        isPending = eventInstance.userStatus.code === 'PENDING';
        isPendingWithReservationSale = eventInstance.userStatus.code === 'PENDING' && eventInstance.userStatus.hasReservationSale;
      }

      switch (action) {
        case 'subscribe':
          if (!isAccepted &&
            !isPending &&
            !attends &&
            (!isTentative || (isTentative && eventInstance.numberOfVacancies > 0)) &&
            // vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 3 * 60 * 60)
            vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - eventInstance.reservationHours * 60 * 60)
          ) {
            // can subscribe until 3 hours before the event
            return true;
          }

          break;
        case 'cancel':
          if ((isAccepted || isTentative) &&
            vm.now.seconds < vm.dateToSeconds(eventInstance.registrationDeadline)
          ) {
            return true;
          }
          break;
        case 'cancel-waitinglist':
          if (participates &&
            isPending &&
            !isPendingWithReservationSale
          ) {
            return true;
          }
          break;
        case 'reject':
          if (threeHoursCancellation(eventInstance)) {
            return true;
          }
          if (!threeHoursCancellation(eventInstance)) {
            return false;
          }
          break;
        default:
      }
      return false;
    }

    function threeHoursCancellation(eventInstance) {
      return vm.now.seconds < (vm.dateToSeconds(eventInstance.startsAt) - 3 * 60 * 60);
    }

    function showCalendar(status) {
      vm.calendarIsVisible = angular.isDefined(status) ? status : true;
    }

    function initHistory() {
      var parameters = {
        limit: 99
      };

      vm.participationHistory = [];
      parameters['filter[]'] = ['contact.id,' + vm.customerContact.contact.id];
      parameters['filter[]'].push('eventParticipantStatus.code,ATTENDED');
      $log.debug(parameters);

      RestUtilsFactory.getFullList(EventParticipantFactory, parameters).then(function (participationHistory) {
        $log.debug(participationHistory);
        vm.participationHistory = participationHistory;
      });
    }

    function initWebshop() {
      vm.products = [];
      // ProductFactory.getProducts().then(function (products) {
      // ProductFactory.getProductsByLabelType('individuele toegangen').then(function (products) {
      //   // console.log(products);
      //   vm.products = products;
      // });
    }

    function getProductByTypeLabel(label, subcategory) {
      vm.products = [];
      if (angular.isDefined(label) && angular.isDefined(subcategory)) {
        return ProductFactory.getProductsByCategories(label, subcategory).then(function (products) {
          vm.products = products;
          vm.allProducts = products;
        });
      }

      if (label) {
        ProductFactory.getProductsByLabelType(label).then(function (result) {
          vm.products = result;
          vm.allProducts = result;
        });
      } else {
        ProductFactory.getProducts().then(function (products) {
          vm.products = products;
          vm.allProducts = products;
        });
      }
    }

    function initWebshopCart() {
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
    }

    function openSidebar(event, state) {
      event.preventDefault();
      if (state) {
        vm.openNav = 'width: 100%; max-width: 500px; margin-left: 500px';
      } else {
        vm.openNav = 'width: 0px; max-width: 0px; margin-left: 0px';
      }
    }

    vm.buyProduct = function (product) {
      return ProductFactory.buyProduct(product).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.addToCart = function (product, inputQuantity) {
      ShoppingCartService.add(product, inputQuantity);
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      vm.maxProductsNumber -= inputQuantity;
      ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', 'product.add_to_cart_success');
    };

    vm.getTicketsCount = function () {
      return ShoppingCartService.getTotalQuantity();
    };

    vm.addIndividualTicketToCart = function (product, inputQuantity) {
      ShoppingCartService.addOrRemoveIndividual(product, inputQuantity);
      vm.cartIndividualItems = ShoppingCartService.getIndividual();
      vm.cartIndividualTotals = ShoppingCartService.totalPriceIndividual();
      vm.individualTicketsCount = ShoppingCartService.countIndividual();
    };

    vm.removeFromCart = function (product) {
      vm.maxProductsNumber += ShoppingCartService.getProduct(product).quantity;
      ShoppingCartService.remove(product);
      vm.cartItems = ShoppingCartService.get();
      vm.cartTotals = ShoppingCartService.totalPrice();
      ToastrNotificationService.showTranslatedNotification('success', 'kiosk.webshop', 'product.remove_from_cart_success');
    };

    vm.checkoutShoppingCart = function () {
      return ProductFactory.checkoutShoppingCart(ShoppingCartService.checkout()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.checkoutIndividualTicketsShoppingCart = function () {
      ShoppingCartService.setGuest(vm.guest);
      return ProductFactory.checkoutIndividualTicketsCart(ShoppingCartService.checkoutForIndividualTickets()).then(function (sale) {
        return SaleFactory.newMolliePayment(sale).then(function (response) {
          $window.location.href = response.url;
        });
      });
    };

    vm.renewJournal = function (product) {
      if (vm.renewJournalIsOpen) {
        return Promise.reject();
      }
      vm.renewJournalModalInstance = $modal.open({
        templateUrl: 'kiosk/views/renew_journal.modal.tpl.html',
        controller: function () {
          this.product = product;

          this.cancel = function () {
            vm.renewJournalModalInstance.dismiss();
          };

          this.renew = function () {
            vm.renewJournalModalInstance.close();
          };
        },
        controllerAs: 'renewJournalCtrl'
      });
      vm.renewJournalIsOpen = true;

      return vm.renewJournalModalInstance.result.then(function () {
        vm.renewJournalIsOpen = false;
        return vm.buyProduct(product);
      }, function () {
        vm.renewJournalIsOpen = false;
        return Promise.reject();
      });
    };

    vm.switchUserLocale = function () {
      $modal.open({
        size: 'sm',
        templateUrl: 'kiosk/views/switch-user-locale.modal.tpl.html',
        controller: ['LOCALES', function (LOCALES) {
          this.locales = LOCALES.locales;
        }],
        controllerAs: 'switchUserLocaleCtrl'
      })
        .result
        // handle close
        .then(function (locale) {
          LocaleService.setLocale(locale, true);
        });
    };

    function updateCategories(category) {
      category.displayFlag = !category.displayFlag;
      vm.fitnessEvents.forEach(function (event) {
        event.fitnessEvent.eventCategories.forEach(function (eventCategory) {
          if (eventCategory.code === category.code) {
            eventCategory.displayFlag = category.displayFlag;
          }
        });
      });
      return category;
    }

    function loadConfig(selectedIndex) {
      vm.selectedBackend = selectedIndex;
      $localStorage.selectedBackend = vm.selectedBackend;
      $localStorage.selectedEnvironment = vm.environments[$localStorage.selectedBackend];
      $timeout(function () {
        $rootScope.environment = vm.environments[$localStorage.selectedBackend];
      });
      if (!UtilsFactory.isNotEmpty($localStorage.redirectLink)) {
        $window.location.href = '/login';
        //$location.path('login');
      } else {
        $location.path($localStorage.redirectLink);
      }
    }

    function back() {
      $state.go('kiosk');
    }

    function subscribeButtonDisabled(eventInstance) {
      var availableCredits = 0;
      angular.forEach(eventInstance.eligibleJournalTypeIds, function (eligibleJournalType) {
        angular.forEach(vm.journalTypes[eligibleJournalType], function (journalId) {
          if (vm.journals[journalId].credits !== null) {
            availableCredits += vm.journals[journalId].credits;
          }
        });
      });

      return (vm.registeredEvents >= availableCredits || vm.contactFitnessCredits <= 0);
    }

    function isDisplayAttentionMessage(eventInstance) {
      var eventStartTimeInSeconds = vm.dateToSeconds(eventInstance.startsAt);
      return (vm.now.seconds > (eventStartTimeInSeconds - (eventInstance.reservationHours * 60 * 60)) &&
        vm.now.seconds < eventStartTimeInSeconds && (!eventInstance.userStatus || eventInstance.userStatus.code === 'REJECTED'));
    }

    function changeLocale(locale) {
      LocaleService.setLocale(locale, false);
    }

    function filterByEntrances(entrance, categoryLabel) {
      var productLabel = '';
      if (vm.currentLocale === 'FR_FR') {
        if (entrance === '(Inwoners)') {
          entrance = '(Habitants)';
        } else if (entrance === '(Niet-inwoners)') {
          entrance = '(Non Habitants)';
        }
      }
      vm.getProductList(categoryLabel).then(function (productsResult) {
        entrance = entrance.toLowerCase();
        if (entrance === '(inwoners)' || entrance === '(habitants)') {
          vm.products = [];
          productsResult.forEach(function (product) {
            productLabel = product.translatedLabel.toLowerCase();
            if (productLabel.split('(niet-inwoners)').length > 1 ||
              productLabel.split('(niet inwoners)').length > 1 ||
              productLabel.split('(non habitants)').length > 1 ||
              productLabel.split('(non-habitants)').length > 1) {
              void 0;
            } else {
              vm.products.push(product);
            }
          });
        } else {
          vm.products = productsResult.filter(function (product) {
            productLabel = product.translatedLabel.toLowerCase();
            return productLabel.split(entrance).length > 1 || productLabel.split('(niet inwoners)').length > 1;
          });
        }
      });
    }

    function getProductList(label) {
      var defer = $q.defer();
      defer.resolve(vm.allProducts ? vm.allProducts : ProductFactory.getProductsByLabelType(label));
      return defer.promise;
    }

    vm.showWebshopCartMenuItem = function () {
      return Object.keys(vm.cartItems).length > 0;
    };

    vm.isNewCustomer = function () {
      return !UtilsFactory.isNotEmpty(vm.fitnessEvents) && !UtilsFactory.isNotEmpty(vm.journals);
    };

    vm.openIndividualTicketsDatePicker = function () {
      vm.individualTicketsDatePickerIsOpened = true;
    };

    vm.openEventsDatePicker = function () {
      vm.eventsDatePickerIsOpened = true;
    };

    vm.activitysFromEnv = function () {
      return vm.environments[$localStorage.selectedBackend].activityTypeForIndividualEvents;
    };

    function previousPurchaseStep() {
      vm.currentPurchaseStep--;
    }

    function nextPurchaseStep() {
      vm.currentPurchaseStep++;
    }

    function getProductListByCategories(category, subcategory) {
      vm.purchaseProducts = [];
      vm.activePurchaseResidentButton = subcategory;
      if (category && subcategory) {
        ProductFactory.getProductsByCategories(category, subcategory).then(function (products) {
          vm.purchaseProducts = products;
        });
      } else {
        ProductFactory.getProducts().then(function (products) {
          vm.purchaseProducts = products;
        });
      }
    }

    function getEventsForIndividualTickets(returnOnly) {
      return EventFactory.one('instances')
        .one('individual_upcoming')
        .one(vm.customer.id)
        .one('contact')
        .one(vm.customerContact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC',
          startsAt: $filter('date')(vm.individualTicketsDate, 'yyyy-MM-dd'),
          'filter[]': 'label,LIKE ' + vm.activityTypeForIndividualEvents
        }).then(function (result) {
          var eventInstances = result.filter(function (eventInstance) {
            return (!vm.selectedEventInstanceForEdit ||
              (vm.selectedEventInstanceForEdit.id !== eventInstance.id && vm.selectedEventInstanceForEdit.reservationCount <= eventInstance.numberOfVacancies)
            );
          });
          if (returnOnly === true) {
            return eventInstances;
          }
          vm.eventsForIndividualTickets = eventInstances;
          vm.noEventsWithAvailablePlacesForIndividual = true;
          vm.eventsForIndividualTickets.forEach(function (eventInstance) {
            if (eventInstance.numberOfVacancies > 0 && vm.now.seconds < vm.dateToSeconds(eventInstance.startsAt)) {
              vm.noEventsWithAvailablePlacesForIndividual = false;
            }
          });
          vm.searchEventsDone = true;
        });
    }

    function selectEventInstance(eventInstance) {
      ShoppingCartService.setReservationEvent(eventInstance);
      vm.selectedEventInstanceForIndividualTickets = eventInstance;
      vm.currentPurchaseStep++;
    }

    function checkPromocode(individual) {
      var productsData = angular.isDefined(individual) ? ShoppingCartService.getIndividualProductsData() : ShoppingCartService.getProductsData();
      if (vm.promocodeValue !== '') {
        PromocodeFactory.checkPromocode(vm.promocodeValue, vm.customer.id, productsData).then(function (result) {
          vm.promocodeValid = result.valid;
          vm.promocodeChecked = true;
          vm.setPromocode();
          if (result.valid) {
            vm.setPromocode(result.promocode, result.percent);
          }
        });
      }
    }

    function setPromocode(promocode, percent) {
      ShoppingCartService.setPromocode(promocode);
      ShoppingCartService.setPromocodePercent(percent);
      vm.promocodePercent = percent;
      vm.cartTotals = ShoppingCartService.getCartTotalsWithPromocode();
      vm.cartTotalDiscount = ShoppingCartService.getCartTotalDiscount();
      vm.cartIndividualTotalDiscount = ShoppingCartService.getCartIndividualTotalDiscount();
      vm.cartIndividualTotals = ShoppingCartService.getCartIndividualTotalsWithPromocode();
    }

    function getEventsForCustomer() {
      var loadingModal = $modal.open({
        template: '<div class="modal-body">\n' +
          '  {{ "app.loading" | translate | uconlyfirst }}\n' +
          '  <span style="text-align: center " class="btn-ng-bs-animated is-active">\n' +
          '          <span class="icons">\n' +
          '              <span class="glyphicon glyphicon-refresh icon-spinner icon-submit"></span>\n' +
          '          </span>\n' +
          '      </span>\n' +
          '</div>',
        size: 'sm'
      });
      return EventFactory.one('instances')
        .one('upcoming_for_customer')
        .one(vm.customer.id)
        .one('contact')
        .one(vm.customerContact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC',
          startsAt: $filter('date')(vm.eventsDate, 'yyyy-MM-dd')
        }).then(function (result) {
          var eventCategoriesTranslatedLabels = {};
          vm.fitnessEvents = result;

          angular.forEach(result, function (eventInstance) {
            eventInstance.forReject = vm.getRejects(eventInstance);
            // calculate calendar data
            eventInstance.date = moment(eventInstance.startsAt).format('YYYY-MM-DD');
            if (angular.isUndefined(vm.calendar[eventInstance.date])) {
              vm.calendar[eventInstance.date] = {
                label: eventInstance.date
              };
            }

            if (angular.isDefined(eventInstance.userStatus) && (eventInstance.userStatus.code === 'ACCEPTED' ||
              eventInstance.userStatus.code === 'PENDING' ||
              eventInstance.userStatus.code === 'TENTATIVE')) {
              vm.registeredEvents++;
            }

            eventInstance.eligibleJournalTypeIds = [];
            eventInstance.classes = [];
            eventInstance.categories = {};
            eventInstance.calendar = {};

            angular.forEach(eventInstance.eventCategories, function (eventCategory) {
              eventCategoriesTranslatedLabels[eventCategory.id] = eventCategory.translatedLabel;
            });

            angular.forEach(eventInstance.eventCategories, function (eventCategory) {
              eventCategory.displayFlag = true;

              // categories
              if (angular.isUndefined(vm.categories[eventCategory.code])) {
                vm.categories[eventCategory.code] = {
                  code: eventCategory.code,
                  label: eventCategory.label,
                  translatedLabel: eventCategoriesTranslatedLabels[eventCategory.id]
                };
              }
              eventInstance.classes.push(eventCategory.code);
            });
            // override classes with fitness course color tag if set
            if (angular.isDefined(eventInstance.fitnessCourses) && angular.isDefined(eventInstance.fitnessCourses[0]) &&
              eventInstance.fitnessCourses[0].tags && angular.isDefined(eventInstance.fitnessCourses[0].tags[0]) &&
              angular.isDefined(eventInstance.fitnessCourses[0].tags[0].id)
            ) {
              eventInstance.classes = [eventInstance.fitnessCourses[0].tags[0].id];
            }
            // calendar
            eventInstance.date = moment(eventInstance.startsAt).format('YYYY-MM-DD');
            if (angular.isUndefined(eventInstance.calendar[eventInstance.date])) {
              eventInstance.calendar[eventInstance.date] = {
                label: eventInstance.date
              };
            }

            // collect eligible journal types
            angular.forEach(eventInstance.fitnessCourses, function (currentFitnessCourse) {
              angular.forEach(currentFitnessCourse.journalTypes, function (currentJournalType) {
                if (eventInstance.eligibleJournalTypeIds.indexOf(currentJournalType.id) === -1) {
                  eventInstance.eligibleJournalTypeIds.push(currentJournalType.id);
                }
              });
            });

            // calculate latest confirmation date
            eventInstance.maxConfirmationDate = moment(eventInstance.startsAt).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
          });
          loadingModal.close();
          vm.fitnessEventsAreLoading = false;
          vm.needReloadEvents = true;
        });
    }

    function getReservationsForCustomer() {
      return EventFactory.one('instances')
        .one('reservations')
        .one('contact')
        .one(vm.customerContact.contact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC'
        }).then(function (result) {
          vm.myFitnessEvents = [];
          angular.forEach(result, function (eventInstance) {
            eventInstance.forReject = vm.getRejects(eventInstance);
            vm.myFitnessEvents.push(eventInstance);
          });
        });
    }

    function getEditableReservationsForCustomer() {
      vm.fitnessEventsAreLoading = true;
      return EventFactory.one('instances')
        .one('reservations')
        .one('contact')
        .one(vm.customerContact.contact.id)
        .get({
          limit: 999,
          sort: 'startsAt,ASC'
        }).then(function (result) {
          vm.editableFitnessEvents = [];
          angular.forEach(result, function (eventInstance) {
            if (vm.showButton('reject', eventInstance)) {
              eventInstance.reservationCount = vm.getRejects(eventInstance).length;
              vm.editableFitnessEvents.push(eventInstance);
            }
          });
        }).finally(function () {
          vm.fitnessEventsAreLoading = false;
        });
    }

    function editEventInstance(eventInstance) {
      vm.eventsForIndividualTickets = [];
      vm.activityTypeForIndividualEvents = eventInstance.label;
      vm.searchEventsDone = false;
      vm.selectedEventInstanceForEdit = eventInstance;
    }

    function displayDialog(indexEnvironment) {
      var newEnv = [];
      vm.filterPosition.forEach(function (site) {
        if (site.index === indexEnvironment) {
          site.isShow = true;
        }
        newEnv.push(site);
      });
      vm.filterPosition = newEnv;
    }

    function closeDialog(indexEnvironment) {
      var newEnv = [];
      vm.filterPosition.forEach(function (site) {
        if (site.index === indexEnvironment) {
          site.isShow = false;
        }
        newEnv.push(site);
      });
      vm.filterPosition = newEnv;
    }
    function changeEventInstance(newEventInstance) {
      var participations = [],
          promiseQueue = [];

      if (!vm.selectedEventInstanceForEdit) {
        return Promise.reject();
      }

      participations = vm.getRejects(vm.selectedEventInstanceForEdit);
      if (participations.length === 0) {
        return Promise.reject();
      }

      return vm.getEventsForIndividualTickets(true).then(function (eventInstances) {
        var placesAvailable = false;
        // make sure places are still available
        eventInstances.forEach(function (eventInstance) {
          if (eventInstance.id === newEventInstance.id && eventInstance.numberOfVacancies >= participations.length) {
            placesAvailable = true;
          }
        });
        if (placesAvailable === false) {
          return Promise.reject();
        }
        participations.forEach(function (participation) {
          promiseQueue.push(EventParticipantFactory.one(participation.id).patch({eventInstance: newEventInstance.id}));
        });
        return Promise.all(promiseQueue).finally(function () {
          $rootScope.guestEventUpdated = true;
          return $state.reload();
        });
      });
    }

    // vm.actionSelection = function (choosenSite) {
    vm.actionSelection = function (index) {
      if (vm.actionSelectionIsOpen) {
        return Promise.reject();
      }
      vm.actionSelectionModalInstance = $modal.open({
        templateUrl: 'kiosk/views/action_selection.modal.tpl.html',
        controller: function () {
          this.selectedSiteIndex = index;

          this.registerAsGuest = function (siteIndex) {
            vm.loadBackendConfig(siteIndex).then(function () {
              vm.registerAsGuest();
            });
          };

          this.loadSiteConfig = function (siteIndex) {
            vm.loadConfig(siteIndex);
          };

          this.close = function () {
            vm.actionSelectionModalInstance.dismiss();
            vm.actionSelectionIsOpen = false;
          };
        },
        controllerAs: 'selectionCtrl'
      });

      vm.actionSelectionIsOpen = true;

      return vm.actionSelectionModalInstance.result.then(function () {
        vm.actionSelectionIsOpen = false;
      }, function () {
        vm.actionSelectionIsOpen = false;
        return Promise.reject();
      });
    };

    function loadBackendConfig(selectedIndex) {
      return new Promise(function (resolve) {
        vm.selectedBackend = selectedIndex;
        $localStorage.selectedBackend = vm.selectedBackend;
        $localStorage.selectedEnvironment = vm.environments[$localStorage.selectedBackend];
        $rootScope.environment = vm.environments[$localStorage.selectedBackend];
        resolve();
      });
    }
  }
}());

/* eslint-disable */
'use strict';
angular.module("ngLocale", [], ["$provide", function($provide) {
var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
function getDecimals(n) {
  n = n + '';
  var i = n.indexOf('.');
  return (i == -1) ? 0 : n.length - i - 1;
}

function getVF(n, opt_precision) {
  var v = opt_precision;

  if (undefined === v) {
    v = Math.min(getDecimals(n), 3);
  }

  var base = Math.pow(10, v);
  var f = ((n * base) | 0) % base;
  return {v: v, f: f};
}

$provide.value("$locale", {
  "DATETIME_FORMATS": {
    "AMPMS": [
      "a.m.",
      "p.m."
    ],
    "DAY": [
      "zondag",
      "maandag",
      "dinsdag",
      "woensdag",
      "donderdag",
      "vrijdag",
      "zaterdag"
    ],
    "ERANAMES": [
      "voor Christus",
      "na Christus"
    ],
    "ERAS": [
      "v.Chr.",
      "n.Chr."
    ],
    "FIRSTDAYOFWEEK": 0,
    "MONTH": [
      "januari",
      "februari",
      "maart",
      "april",
      "mei",
      "juni",
      "juli",
      "augustus",
      "september",
      "oktober",
      "november",
      "december"
    ],
    "SHORTDAY": [
      "zo",
      "ma",
      "di",
      "wo",
      "do",
      "vr",
      "za"
    ],
    "SHORTMONTH": [
      "jan.",
      "feb.",
      "mrt.",
      "apr.",
      "mei",
      "jun.",
      "jul.",
      "aug.",
      "sep.",
      "okt.",
      "nov.",
      "dec."
    ],
    "WEEKENDRANGE": [
      5,
      6
    ],
    "fullDate": "EEEE d MMMM y",
    "longDate": "d MMMM y",
    "medium": "d MMM y HH:mm:ss",
    "mediumDate": "d MMM y",
    "mediumTime": "HH:mm:ss",
    "short": "d/MM/yy HH:mm",
    "shortDate": "d/MM/yy",
    "shortTime": "HH:mm"
  },
  "NUMBER_FORMATS": {
    "CURRENCY_SYM": "\u20ac",
    "DECIMAL_SEP": ",",
    "GROUP_SEP": ".",
    "PATTERNS": [
      {
        "gSize": 3,
        "lgSize": 3,
        "maxFrac": 3,
        "minFrac": 0,
        "minInt": 1,
        "negPre": "-",
        "negSuf": "",
        "posPre": "",
        "posSuf": ""
      },
      {
        "gSize": 3,
        "lgSize": 3,
        "maxFrac": 2,
        "minFrac": 2,
        "minInt": 1,
        "negPre": "\u00a4\u00a0-",
        "negSuf": "",
        "posPre": "\u00a4\u00a0",
        "posSuf": ""
      }
    ]
  },
  "id": "nl-be",
  "pluralCat": function(n, opt_precision) {  var i = n | 0;  var vf = getVF(n, opt_precision);  if (i == 1 && vf.v == 0) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
});
}]);
/* eslint-enable */

/* eslint-disable */
'use strict';
angular.module("ngLocale", [], ["$provide", function($provide) {
  var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
  $provide.value("$locale", {
    "DATETIME_FORMATS": {
      "AMPMS": [
        "AM",
        "PM"
      ],
      "DAY": [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi"
      ],
      "ERANAMES": [
        "avant J\u00e9sus-Christ",
        "apr\u00e8s J\u00e9sus-Christ"
      ],
      "ERAS": [
        "av. J.-C.",
        "ap. J.-C."
      ],
      "FIRSTDAYOFWEEK": 0,
      "MONTH": [
        "janvier",
        "f\u00e9vrier",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "ao\u00fbt",
        "septembre",
        "octobre",
        "novembre",
        "d\u00e9cembre"
      ],
      "SHORTDAY": [
        "dim.",
        "lun.",
        "mar.",
        "mer.",
        "jeu.",
        "ven.",
        "sam."
      ],
      "SHORTMONTH": [
        "janv.",
        "f\u00e9vr.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "ao\u00fbt",
        "sept.",
        "oct.",
        "nov.",
        "d\u00e9c."
      ],
      "STANDALONEMONTH": [
        "janvier",
        "f\u00e9vrier",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "ao\u00fbt",
        "septembre",
        "octobre",
        "novembre",
        "d\u00e9cembre"
      ],
      "WEEKENDRANGE": [
        5,
        6
      ],
      "fullDate": "EEEE d MMMM y",
      "longDate": "d MMMM y",
      "medium": "d MMM y HH:mm:ss",
      "mediumDate": "d MMM y",
      "mediumTime": "HH:mm:ss",
      "short": "dd/MM/y HH:mm",
      "shortDate": "dd/MM/y",
      "shortTime": "HH:mm"
    },
    "NUMBER_FORMATS": {
      "CURRENCY_SYM": "\u20ac",
      "DECIMAL_SEP": ",",
      "GROUP_SEP": "\u00a0",
      "PATTERNS": [
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 3,
          "minFrac": 0,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "",
          "posPre": "",
          "posSuf": ""
        },
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 2,
          "minFrac": 2,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "\u00a0\u00a4",
          "posPre": "",
          "posSuf": "\u00a0\u00a4"
        }
      ]
    },
    "id": "fr-fr",
    "localeID": "fr_FR",
    "pluralCat": function(n, opt_precision) {  var i = n | 0;  if (i == 0 || i == 1) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
  });
}]);
/* eslint-enable */

/* eslint-disable */
'use strict';
angular.module("ngLocale", [], ["$provide", function($provide) {
var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
function getDecimals(n) {
  n = n + '';
  var i = n.indexOf('.');
  return (i == -1) ? 0 : n.length - i - 1;
}

function getVF(n, opt_precision) {
  var v = opt_precision;

  if (undefined === v) {
    v = Math.min(getDecimals(n), 3);
  }

  var base = Math.pow(10, v);
  var f = ((n * base) | 0) % base;
  return {v: v, f: f};
}

$provide.value("$locale", {
  "DATETIME_FORMATS": {
    "AMPMS": [
      "AM",
      "PM"
    ],
    "DAY": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "ERANAMES": [
      "Before Christ",
      "Anno Domini"
    ],
    "ERAS": [
      "BC",
      "AD"
    ],
    "FIRSTDAYOFWEEK": 6,
    "MONTH": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "SHORTDAY": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "SHORTMONTH": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "STANDALONEMONTH": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "WEEKENDRANGE": [
      5,
      6
    ],
    "fullDate": "EEEE, MMMM d, y",
    "longDate": "MMMM d, y",
    "medium": "MMM d, y h:mm:ss a",
    "mediumDate": "MMM d, y",
    "mediumTime": "h:mm:ss a",
    "short": "M/d/yy h:mm a",
    "shortDate": "M/d/yy",
    "shortTime": "h:mm a"
  },
  "NUMBER_FORMATS": {
    "CURRENCY_SYM": "$",
    "DECIMAL_SEP": ".",
    "GROUP_SEP": ",",
    "PATTERNS": [
      {
        "gSize": 3,
        "lgSize": 3,
        "maxFrac": 3,
        "minFrac": 0,
        "minInt": 1,
        "negPre": "-",
        "negSuf": "",
        "posPre": "",
        "posSuf": ""
      },
      {
        "gSize": 3,
        "lgSize": 3,
        "maxFrac": 2,
        "minFrac": 2,
        "minInt": 1,
        "negPre": "-\u00a4",
        "negSuf": "",
        "posPre": "\u00a4",
        "posSuf": ""
      }
    ]
  },
  "id": "en-us",
  "localeID": "en_US",
  "pluralCat": function(n, opt_precision) {  var i = n | 0;  var vf = getVF(n, opt_precision);  if (i == 1 && vf.v == 0) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
});
}]);
/* eslint-enable */

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('settings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('set-locale', {
        url: '/set-locale/:locale',
        onEnter: ['$stateParams', '$state', 'LocaleService', function ($stateParams, $state, LocaleService) {
          LocaleService.setLocale($stateParams.locale);
          $state.go('kiosk', {}, {reload: true});
        }]
      })
      .state('toggle-locale', {
        url: '/toggle-locale',
        onEnter: ['$stateParams', '$state', 'LocaleService', function ($stateParams, $state, LocaleService) {
          LocaleService.toggleLocale();
          $state.go('kiosk', {}, {reload: true});
        }]
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('kiosk')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('kiosk', {
        url: '/',
        views: {
          '@': {
            templateUrl: 'kiosk/views/kiosk.tpl.html'
          },
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.environment.tpl.html',
            //templateUrl: 'kiosk/views/kiosk.environmentclose.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.login', {
        url: 'login',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.login.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: ["$rootScope", "$log", function ($rootScope, $log) {
          $log.debug('enter kiosk state');
          $rootScope.ignoreAuthenticationErrors = true;
        }],
        onExit: ["$rootScope", "$log", function ($rootScope, $log) {
          $log.debug('exit kiosk state');
          $rootScope.ignoreAuthenticationErrors = false;
        }]
      })
      .state('kiosk.customer', {
        url: 'customer/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.customer.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.guest', {
        url: 'guest/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.guest.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.guest_login', {
        url: 'guest/login/:token',
        views: {
          'content@kiosk': {
            template: ' ',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: ["$rootScope", function ($rootScope) {
          $rootScope.anonymous = true;
        }],
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.guest_edit', {
        url: 'guest/:customerId/edit',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.guest_edit.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('user_activation', {
        url: '/users/activate/:token',
        views: {
          '@': {
            templateUrl: 'kiosk/views/user_activation.tpl.html'
          },
          'content@user_activation': {
            templateUrl: 'kiosk/views/user_activation.content.tpl.html',
            controller: 'UserActivationCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: ["$rootScope", function ($rootScope) {
          $rootScope.anonymous = true;
        }],
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('user_password_reset', {
        url: '/users/password/reset/:token',
        views: {
          '@': {
            templateUrl: 'kiosk/views/user_activation.tpl.html'
          },
          'content@user_password_reset': {
            templateUrl: 'kiosk/views/user_activation.content.tpl.html',
            controller: 'UserPasswordResetCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: ["$rootScope", function ($rootScope) {
          $rootScope.anonymous = true;
        }],
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.choose_site', {
        url: 'choose_site',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.choose_site.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      ;
  }
}());

// (function () {
//   'use strict';
//
//   /* Set the width of the sidebar to 250px and the left margin of the page content to 250px */
//   function openNav() {
//     document.getElementById("mySidebar").style.width = "250px";
//     document.getElementById("main").style.marginLeft = "250px";
//   }
//
//   /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
//   function closeNav() {
//     document.getElementById("mySidebar").style.width = "0";
//     document.getElementById("main").style.marginLeft = "0";
//   }
// }());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.factory:UtilsFactory
   *
   * @description
   *
   */
  UtilsFactory.$inject = ["$state", "$log", "$modal", "$filter"];
  angular
    .module('sportoase')
    .factory('UtilsFactory', UtilsFactory);

  function UtilsFactory(
      $state,
      $log,
      $modal,
      $filter
    ) {
    var vm = this;
    vm.confirmationModalInstance = null;
    vm.showingConfirmationModal = false;

    function displayConfirmationModal(title, body, resultHandler) {
      if (vm.showingConfirmationModal) {
        return;
      }

      vm.confirmationModalInstance = $modal.open({
        templateUrl: 'views/confirmation.modal.view.tpl.html',
        controller: 'ConfirmationPromptCtrl',
        controllerAs: 'confirmationPromptCtrl',
        resolve: {
          title: function () {
            return title;
          },
          body: function () {
            return body;
          }
        }
      });

      vm.showingConfirmationModal = true;

      vm.confirmationModalInstance.result.then(function (returnValue) {
        vm.showingConfirmationModal = false;
        resultHandler(returnValue);
      }, function (returnValue) {
        $log.debug('Reason for closing: ' + returnValue);
        vm.showingConfirmationModal = false;
      });
    }

    return {
      reloadState: function () {
        $log.debug('reloading current state');
        $state.go($state.current, {}, {reload: true});
      },
      intervalStringToValuesObject: function (intervalString) {
        var dateString = '',
            timeString = '',
            dateDesignatorIndex = intervalString.indexOf('P'),
            timeDesignatorIndex = intervalString.indexOf('T'),
            result = {};
        // valid interval string starts with P(eriod)
        if (dateDesignatorIndex === 0) {
          // divide the string into date and time portion
          if (timeDesignatorIndex > -1) {
            timeString = intervalString.slice(timeDesignatorIndex);
            dateString = intervalString.slice(0, timeDesignatorIndex);
          } else {
            dateString = intervalString;
          }

          // use regex magic to determine the actual values
          result.days = parseInt(dateString.match(/(\d+)(?:D)/g), 10);
          result.months = parseInt(dateString.match(/(\d+)(?:M)/g), 10);
          result.years = parseInt(dateString.match(/(\d+)(?:Y)/g), 10);

          if (timeString.length) {
            result.hours = parseInt(dateString.match(/(\d+)(?:H)/g), 10);
            result.minutes = parseInt(dateString.match(/(\d+)(?:M)/g), 10);
            result.seconds = parseInt(dateString.match(/(\d+)(?:S)/g), 10);
          }
        }
        return result;
      },
      valuesObjectToIntervalString: function (valuesObject) {
        var result = 'P',
            hasHours = this.isNotEmpty(valuesObject.hours),
            hasMinutes = this.isNotEmpty(valuesObject.minutes),
            hasSeconds = this.isNotEmpty(valuesObject.seconds);

        if (this.isNotEmpty(valuesObject.days)) {
          result += valuesObject.days + 'D';
        }
        if (this.isNotEmpty(valuesObject.months)) {
          result += valuesObject.months + 'M';
        }
        if (this.isNotEmpty(valuesObject.years)) {
          result += valuesObject.years + 'Y';
        }

        if (hasHours || hasMinutes || hasSeconds) {
          result += 'T';

          if (hasHours) {
            result += valuesObject.hours + 'H';
          }
          if (hasMinutes) {
            result += valuesObject.minutes + 'M';
          }
          if (hasSeconds) {
            result += valuesObject.seconds + 'S';
          }
        }
        return result;
      },
      isNotEmpty: function (variable) {
        var notNull = angular.isDefined(variable) && variable !== null;
        if (!notNull) {
          return false;
        }
        if (angular.isString(variable) || angular.isArray(variable)) {
          return variable.length > 0;
        }
        if (angular.isObject(variable) && !angular.isDate(variable)) {
          return Object.keys(variable).length > 0;
        }

        return true;
      },
      promiseLoop: function (collection, loopCallBack) {
        var elementsToProcess = 0;
        return new Promise(function (resolve, reject) {
          if (angular.isArray(collection)) {
            if (collection.length === 0) {
              resolve();
            } else {
              elementsToProcess = collection.length;
              angular.forEach(collection, function (element) {
                Promise.resolve(loopCallBack(element))
                .then(function () {
                  --elementsToProcess;
                  if (elementsToProcess <= 0) {
                    resolve();
                  }
                });
              });
            }
          } else {
            reject('Collection parameter should be an array.');
          }
        });
      },
      showConfirmationModal: function (title, body, handler) {
        return displayConfirmationModal(
          $filter('uconlyfirst')($filter('translate')(title)),
          $filter('uconlyfirst')($filter('translate')(body)),
          handler
        );
      },
      showFormattedConfirmationModal: function (titleFormat, title, bodyFormat, body, handler) {
        return displayConfirmationModal(
          $filter('uconlyfirst')($filter('sprintf')($filter('translate')(titleFormat), $filter('translate')(title))),
          $filter('uconlyfirst')($filter('sprintf')($filter('translate')(bodyFormat), $filter('translate')(body))),
          handler
        );
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name sportoase.factory:CountryFactory
   *
   * @description
   *
   */
  CountryFactory.$inject = ["Restangular"];
  angular
    .module('sportoase')
    .factory('CountryFactory', CountryFactory);

  function CountryFactory(Restangular) {
    var vm = this;
    vm.initialized = false;
    vm.countries = [];
    vm.init = init;
    vm.init();

    function init() {
      // Get all Countries
      return Restangular
      .all('countries')
      .customGET('')
      .then(function (resultCountries) {
        vm.countries = resultCountries.results;
        vm.initialized = true;
      });
    }

    return {
      getCountries: function () {
        return new Promise(function (resolve) {
          if (vm.initialized) {
            resolve(vm.countries);
          } else {
            vm.init()
            .then(function () {
              resolve(vm.countries);
            });
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name sportoase.directive:promiseButton
   * @restrict AEC
   *
   */
  /* @ngInject */
  promiseButton.$inject = ["$timeout"];
  angular
    .module('sportoase')
    .directive('promiseButton', promiseButton);

  function promiseButton($timeout) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        id: '@',
        callback: '&',
        options: '=?',
        defaultText: '@',
        submittingText: '@',
        successText: '@',
        errorText: '@'
      },
      controller: ['$scope', '$filter', function ($scope, $filter) {
        $scope.defaultText = $scope.defaultText || $filter('uconlyfirst')($filter('translate')('app.submit'));
        $scope.submittingText = $scope.submittingText || $filter('uconlyfirst')($filter('translate')('app.submitting'));
        $scope.successText = $scope.successText || $filter('uconlyfirst')($filter('translate')('app.completed'));
        $scope.errorText = $scope.errorText || $filter('uconlyfirst')($filter('translate')('app.error_occured'));

        $scope.options = $scope.options || {};
        $scope.options = {
          defaultClass: $scope.options.defaultClass || 'btn-primary',
          submittingClass: $scope.options.submittingClass || 'btn-primary',
          successClass: $scope.options.successClass || 'btn-primary',
          errorClass: $scope.options.errorClass || 'btn-danger',
          sizeClass: $scope.options.sizeClass || null,
          submittingIcon: $scope.options.submittingIcon || 'glyphicon glyphicon-refresh',
          successIcon: $scope.options.successIcon || 'glyphicon glyphicon-ok',
          errorIcon: $scope.options.errorIcon || 'glyphicon glyphicon-remove',
          formIsInvalid: $scope.options.formIsInvalid || '',
          animationCompleteTime: $scope.options.animationCompleteTime || '2000'
        };
      }],
      template:
        '<button id="{{id}}" ng-click="executeCallBack()" class="btn {{ buttonClass }} {{ options.sizeClass }} btn-ng-bs-animated">' +
          '<span class="icons">' +
            '<span class="{{ options.submittingIcon }} icon-spinner icon-submit hidden"></span>' +
            '<span class="{{ options.successIcon }} icon-result icon-success hidden"></span>' +
            '<span class="{{ options.errorIcon }} icon-result icon-error hidden"></span>' +
          '</span>' +
          '<span class="text">' +
            '<span ng-if="state == \'default\'" class="text">{{ defaultText }}</span>' +
            '<span ng-if="state == \'submit\'" class="text">{{ submittingText }}</span>' +
            '<span ng-if="state == \'success\'" class="text">{{ successText }}</span>' +
            '<span ng-if="state == \'error\'" class="text">{{ errorText }}</span>' +
          '</span>' +
        '</button>',
      link: function (scope, element) {
        var icons = {
          submitting: angular.element(element[0].querySelector('.icon-submit')),
          result: angular.element(element[0].querySelectorAll('.icon-result')),
          success: angular.element(element[0].querySelector('.icon-success')),
          error: angular.element(element[0].querySelector('.icon-error'))
        };

        function resetButton() {
          scope.result = null;
          scope.buttonClass = scope.options.defaultClass;
          scope.state = 'default';
          icons.result.addClass('hidden');
          element.removeClass('is-active').attr('disabled', false);
        }
        resetButton();

        scope.executeCallBack = function () {
          scope.state = 'submit';
          scope.buttonClass = scope.options.submittingClass;
          icons.submitting.removeClass('hidden');
          element.attr('disabled', true).addClass('is-active');
          scope.callback()
          .then(function () {
            scope.state = 'success';
            scope.buttonClass = scope.options.successClass;
            icons.submitting.addClass('hidden');
            icons.success.removeClass('hidden');
            $timeout(resetButton, scope.options.animationCompleteTime);
          }, function () {
            scope.state = 'error';
            scope.buttonClass = scope.options.errorClass;
            icons.submitting.addClass('hidden');
            icons.error.removeClass('hidden');
            $timeout(resetButton, scope.options.animationCompleteTime);
          });
        };
      }
    };
  }
}());

(function () {
  'use strict';
  // Async bootstrap made possible by Marius Schulz
  // https://blog.mariusschulz.com/2014/10/22/asynchronously-bootstrapping-angularjs-applications-with-server-side-data
  angular
    .module('sportoase.config', []);

  fetchData();

  function fetchData() {
    var $http = angular.injector(['ng']).get('$http'),
        /* eslint-env browser */
        selectedBackend = (localStorage.getItem('ngStorage-selectedBackend') ? localStorage.getItem('ngStorage-selectedBackend') : 0);

    $http.get('../env/env_vars.json?t=' + (new Date().getTime())).then(function (response) {
      var environments = response.data.OAUTH_CONFIG,
          config = response.data.OAUTH_CONFIG[selectedBackend],
          params = response.data.PARAMS;

      angular
        .module('sportoase.config')
        .constant('ENVIRONMENTS', environments)
        .constant('OAUTH_CONFIG', config)
        .constant('PARAMS', params);

      bootstrapApplication();
    }, function (errorResponse) {
      // Handle error case
      void 0;
    });
  }

  function bootstrapApplication() {
    var $document = angular.injector(['ng']).get('$document');
    angular.element($document).ready(function () {
      void 0;
      void 0;
      angular.bootstrap($document, ['sportoase']);
    });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name auth
   * @description
   *
   */
  angular
    .module('auth', [
      'ui.router',
      'sportoase.config',
      'angular-oauth2',
      'ngCookies'
    ])
    .factory('customOauthInterceptor', customOauthInterceptor)
    .config(["$httpProvider", "OAuthProvider", "OAUTH_CONFIG", function ($httpProvider, OAuthProvider, OAUTH_CONFIG) {
      'ngInject';
      OAuthProvider.configure({
        baseUrl: OAUTH_CONFIG.baseUrl,
        clientId: OAUTH_CONFIG.clientId,
        clientSecret: OAUTH_CONFIG.clientSecret,
        grantPath: OAUTH_CONFIG.grantPath,
        revokePath: OAUTH_CONFIG.revokePath
      });

      $httpProvider.interceptors.push('customOauthInterceptor');
    }])
    .run(["$rootScope", "$state", "$log", "OAuth", "UtilsFactory", "ToastrNotificationService", "$cookies", function ($rootScope, $state, $log, OAuth, UtilsFactory, ToastrNotificationService, $cookies) {
      'ngInject';
      $rootScope.refreshingToken = false;

      // handle oauth errors
      $rootScope.$on('oauth:error', function (event, rejection) {
        // If the bypass flag is set, ignore oauth errors
        if (event.targetScope && angular.isDefined(event.targetScope.ignoreAuthenticationErrors) &&
          event.targetScope.ignoreAuthenticationErrors) {
          $log.debug('AUTH: Bypassing authentication errors check due to flag');
          return;
        }

        $log.debug('oauth error!');
        $log.debug(rejection.data);
        if (rejection.data.error === 'invalid_token') {
          $log.debug('invalid token -> refreshing!');
          $rootScope.$emit('refreshToken');
        }
        if (rejection.data.error === 'invalid_grant') {
          $log.debug('invalid grant!');
          $rootScope.$broadcast('redirectToLogin', $state.current);
          return;
        }
      });

      // redirect to login page when appropriate
      $rootScope.$on('checkUserCredentials', function (event, next) {
        // If this is an anonymous call, don't redirect to login page
        if (event.targetScope && angular.isDefined(event.targetScope.anonymous) && event.targetScope.anonymous) {
          $log.debug('AUTH: Bypassing credentials check due to anonymous call');
          return;
        }

        // event.stopPropagation();
        // second check prevents refresh attempts before we have a refresh token
        if (!OAuth.isAuthenticated() && (angular.isUndefined($cookies.get('token')) || !OAuth.getRefreshToken())) {
          $log.debug('user is not authenticated and token is not refreshed');
          $rootScope.$emit('redirectToLogin', next);
        }
      });

      // redirect to login page when appropriate
      $rootScope.$on('$viewContentLoaded', function (event, next) {
        $rootScope.$emit('checkUserCredentials', next);
      });

      // refresh the token
      $rootScope.$on('refreshToken', function () {
        if (angular.isUndefined($rootScope.refreshingToken) || !$rootScope.refreshingToken) {
          $rootScope.refreshingToken = true;
          $log.debug('Refreshing oauth token');
          OAuth.getRefreshToken().then(function () {
            $rootScope.refreshingToken = false;
          }, function (errorMsg) {
            $log.debug('could not refresh token! -> ' + errorMsg);
            $rootScope.refreshingToken = false;
          });
        } else {
          $log.debug('token refresh lock already set!');
        }
      });

      // redirect to login page when appropriate
      $rootScope.$on('redirectToLogin', function (event, next) {
        if (angular.isUndefined(next)) {
          next = $state.current;
        }

        if ($state.current.name !== 'kiosk' && next.name !== 'kiosk') {
          $log.debug('redirecting to login page');
          $log.debug($state.current.name);
          $log.debug(next.name);
          // preserve page the user was going to
          $rootScope.returnToState = next.name;
          event.preventDefault();
          $state.go('kiosk.login');
        }
      });
    }]);

  customOauthInterceptor.$inject = ['$q', '$cookies', '$rootScope', '$log', '$timeout', '$injector', 'OAuthToken', '_'];
  function customOauthInterceptor($q, $cookies, $rootScope, $log, $timeout, $injector, OAuthToken, _) {
    return {
      request: function (config) {
        if (OAuthToken.getAuthorizationHeader()) {
          config.headers = config.headers || {};
          config.headers.Authorization = OAuthToken.getAuthorizationHeader();
        }
        return config;
      },
      responseError: function (rejection) {
        if (_.indexOf([401, 403], rejection.status) > -1) {
          if (angular.isDefined($rootScope.anonymous) && ($rootScope.anonymous === true)) {
            $log.warn('AUTH: Response authentication error, but ignored during anonymous call');
            return null;
          }
        }

        // the angular oauth 2 module expects a different rejection format than the one
        // we receive from the symfony backend.
        // This custom oauth interceptor is meant to solve that problem.
        if (rejection.status === 401 && (rejection.data && rejection.data.error === 'invalid_grant') ||
          rejection.headers('www-authenticate') && rejection.headers('www-authenticate').indexOf('Bearer') === 0) {
          $log.debug('custom oauth request intercept! (401)');
          // no refresh possible - emit error
          if (angular.isUndefined($cookies.get('token'))) {
            $log.debug('no token present, emitting error');
            $rootScope.$emit('oauth:error', rejection);
          } else {
            $log.debug('attempting to refresh token');
            $rootScope.$emit('refreshToken');
            return $timeout(function () {
              var $http = $injector.get('$http');
              return $http(rejection.config);
            }, 2000);
          }
        } else if (rejection.status === 403) {
          $log.debug('forbidden - redirect to login');
          $rootScope.$emit('redirectToLogin');
        } else if (
          rejection.data.hasOwnProperty('errors') && rejection.data.errors.hasOwnProperty('children') &&
          $injector.get('UtilsFactory').isNotEmpty(rejection.data.errors.children) &&
          $injector.get('UtilsFactory').isNotEmpty(rejection.config.headers['x-entity'])) {
          $log.debug('Specific form error data found - sending broadcast');
          $rootScope.$broadcast('showFormErrorMsgBroadcast', rejection);
        } else {
          $rootScope.$broadcast('showErrorMsgToastrBroadcast', rejection);
        }

        return $q.reject(rejection);
      }
    };
  }
}());

(function () {
  'use strict';

  // filters
  angular.module('filters', [])

  // uconlyfirst filter
  .filter('uconlyfirst', function () {
    return function (input) {
      return angular.isDefined(input) ? input.charAt(0).toUpperCase() + input.slice(1) : input;
    };
  })

  // yesno filter
  .filter('yesno', ["translateFilter", function (translateFilter) {
    return function (input) {
      if (input === true || input > 0) {
        return translateFilter('app.yes');
      } else if (input === false || input <= 0) {
        return translateFilter('app.no');
      }
      return input;
    };
  }])

  // range filter
  .filter('range', function () {
    return function (input, min, max) {
      var i;
      min = parseInt(min, 10);
      max = parseInt(max, 10);
      for (i = min; i <= max; i++) {
        input.push(i);
      }
      return input;
    };
  });
}());

(function () {
  'use strict';

  routeConfig.$inject = ["$urlRouterProvider"];
  translationConfig.$inject = ["$translateProvider", "PARAMS", "$windowProvider"];
  angular
    .module('sportoase')
    .config(routeConfig)
    .config(translationConfig);

  function routeConfig($urlRouterProvider) {
    $urlRouterProvider.otherwise('');
  }

  function translationConfig($translateProvider, PARAMS, $windowProvider) {
    var lang = getPreferredLocale(PARAMS, $windowProvider);
    // get warnings in the developer console regarding forgotten IDs in translations
    $translateProvider.useMissingTranslationHandlerLog();

    $translateProvider.useStaticFilesLoader({
      // path to translation files
      prefix: 'resources/locale-',
      suffix: '.json?t=' + (new Date().getTime())
    });
    $translateProvider.useSanitizeValueStrategy('escape');
    $translateProvider.preferredLanguage(lang);
    $translateProvider.use(lang);
    $translateProvider.useLocalStorage();
  }

  function getPreferredLocale(PARAMS, $windowProvider) {
    var userLocale, userLanguage = 1, hostname = $windowProvider.$get().location.host;

    /* eslint-disable */
    userLocale = (navigator.language || navigator.userLanguage);
    /* eslint-enable */

    if (PARAMS.HOST_LANGUAGE[hostname]) {
      userLanguage = PARAMS.HOST_LANGUAGE[hostname];
      // eslint-disable-next-line no-undef
    } else {
      userLanguage = userLocale;
      if (userLanguage.indexOf('-') > -1) {
        userLanguage = userLanguage.substring(0, userLanguage.indexOf('-')).toLowerCase();
      }
    }

    switch (userLanguage) {
      case 'nl':
        return 'NL_BE';
      case 'en':
        return 'EN_US';
      case 'fr':
        return 'FR_FR';
      default:
        return 'NL_BE';
    }
  }
}());
