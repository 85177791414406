(function () {
  'use strict';

  angular
    .module('kiosk')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('kiosk', {
        url: '/',
        views: {
          '@': {
            templateUrl: 'kiosk/views/kiosk.tpl.html'
          },
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.environment.tpl.html',
            //templateUrl: 'kiosk/views/kiosk.environmentclose.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.login', {
        url: 'login',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.login.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope, $log) {
          $log.debug('enter kiosk state');
          $rootScope.ignoreAuthenticationErrors = true;
        },
        onExit: function ($rootScope, $log) {
          $log.debug('exit kiosk state');
          $rootScope.ignoreAuthenticationErrors = false;
        }
      })
      .state('kiosk.customer', {
        url: 'customer/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.customer.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.guest', {
        url: 'guest/:customerId',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.guest.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('kiosk.guest_login', {
        url: 'guest/login/:token',
        views: {
          'content@kiosk': {
            template: ' ',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.guest_edit', {
        url: 'guest/:customerId/edit',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.guest_edit.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      .state('user_activation', {
        url: '/users/activate/:token',
        views: {
          '@': {
            templateUrl: 'kiosk/views/user_activation.tpl.html'
          },
          'content@user_activation': {
            templateUrl: 'kiosk/views/user_activation.content.tpl.html',
            controller: 'UserActivationCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('user_password_reset', {
        url: '/users/password/reset/:token',
        views: {
          '@': {
            templateUrl: 'kiosk/views/user_activation.tpl.html'
          },
          'content@user_password_reset': {
            templateUrl: 'kiosk/views/user_activation.content.tpl.html',
            controller: 'UserPasswordResetCtrl',
            controllerAs: 'passwordCtrl'
          }
        },
        onEnter: function ($rootScope) {
          $rootScope.anonymous = true;
        },
        onLeave: function ($rootScope) {
          $rootScope.anonymous = false;
        }
      })
      .state('kiosk.choose_site', {
        url: 'choose_site',
        views: {
          'content@kiosk': {
            templateUrl: 'kiosk/views/kiosk.choose_site.tpl.html',
            controller: 'KioskCtrl',
            controllerAs: 'kioskCtrl'
          }
        }
      })
      ;
  }
}());
