(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.factory:SettingsService
   *
   * @description
   *
   */
  /* @ngInject */
  angular
    .module('settings')
    .factory('SettingsService', SettingsService);

  function SettingsService(
    $localStorage,
    $timeout,
    SettingFactory
  ) {
    var vm = this;
    vm.get = get;
    vm.reloadSettings = reloadSettings;

    function get(key, defaultValue) {
      var setting = null;

      if (angular.isUndefined($localStorage.currentUserSettings)) {
        return null;
      }

      if (key in $localStorage.currentUserSettings) {
        setting = $localStorage.currentUserSettings[key];
        if (angular.isString(setting) && (setting.toLowerCase() === 'false' || setting.toLowerCase() === 'off')) {
          setting = false;
        }
      } else if (angular.isDefined(defaultValue)) {
        setting = defaultValue;
      }

      return setting;
    }

    function reloadSettings() {
      return SettingFactory.getListSimple().then(function (result) {
        $localStorage.currentUserSettings = result;
        // run angular digest so all buttons show up in the navbar
        $timeout();
      });
    }

    return vm;
  }
}());
