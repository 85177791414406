(function () {
  'use strict';

  /* @ngdoc object
   * @name sportoase
   * @description
   *
   */
  angular.module('sportoase', [
    'angular-repeat-n',
    'angular.filter',
    'angularMoment',
    'auth',
    'base64',
    'duScroll',
    'filters',
    'kiosk',
    'l42y.sprintf',
    'ng-currency',
    'ngAnimate',
    'ngCookies',
    'ngDialog',
    'ngStorage',
    'pascalprecht.translate',
    'restangular',
    'settings',
    'sportoase.config',
    'timer',
    'tmh.dynamicLocale',
    'toastr',
    'toastrNotification',
    'ui.bootstrap',
    'ui.mask',
    'ui.router',
    'ui.select',
    'ui.tree',
    'ui.utils.masks',
    'underscore',
    'utils'
  ]).constant('LOCALES', {
    locales: {
      EN_US: 'locale.en_us',
      NL_BE: 'locale.nl_be',
      FR_FR: 'locale.fr_fr'
    }
  })
    .config(config)
    .config(function (tmhDynamicLocaleProvider) {
      tmhDynamicLocaleProvider.defaultLocale('nl-be');
    })
    .run(function (
      $rootScope,
      ToastrNotificationService,
      $filter,
      $compile,
      $modalStack
    ) {
      'ngInject';

      $rootScope.$on('$stateChangeStart', function () {
        $rootScope.ui = {
          navigation: true,
          header: true,
          login: false,
          loading: false
        };
        $modalStack.dismissAll('skip');
      });

      // Show errorMsg when a call to backend fails
      $rootScope.$on('showErrorMsgToastrBroadcast', function (event, errorMsg) {
        event.preventDefault();
        console.log('errorMsg: ', errorMsg);
        ToastrNotificationService.showTranslatedNotification(
          'error',
          'app.error',
          'app.general-error'
        );
      });
    }
  )
  .directive('datepickerTimezone', function () {
    var date;
    return {
      restrict: 'A',
      priority: 1,
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
        ctrl.$formatters.push(function (value) {
          // console.log('value1: ' + value);
          if (angular.isDate(value) && value.toISOString().length > 0) {
            date = new Date(Date.parse(value));
            date = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
          } else {
            date = null;
          }
          return date;
        });

        ctrl.$parsers.push(function (value) {
          // console.log('value2: ' + value);
          if (angular.isDate(value) && value.toISOString().length > 0) {
            date = new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
          } else {
            date = null;
          }
          return date;
        });
      }
    };
  })
  .directive('dynamicFormErrors', function (UtilsFactory) {
    return {
      restrict: 'A',
      priority: 1000,
      link: function (scope, element) {
        scope.$on('showFormErrorMsgBroadcast', function (event, errorMsg) {
          var currentSelector,
              errorMessage,
              inputElement,
              dynamicMessagesDiv,
              formGroupElement,
              entity = errorMsg.config.headers['x-entity'];

          event.preventDefault();
            // prevent double/stale error messages by clearing the previous divs if necessary
          dynamicMessagesDiv = element.find('.dynamic-error-messages');
          dynamicMessagesDiv.remove();

          angular.forEach(errorMsg.data.errors.children, function (errors, field) {
              // uppercase first letter of field and concat to entity string
            currentSelector = entity + (field.charAt(0).toUpperCase() + field.slice(1));
            inputElement = element.find('#' + currentSelector);
            formGroupElement = inputElement.parent();

            if (UtilsFactory.isNotEmpty(errors)) {
              if (inputElement.length) {
                formGroupElement.addClass('has-error');

                  // append error messages
                errorMessage = '<div class="dynamic-error-messages">';
                angular.forEach(errors, function (error) {
                  errorMessage += '<div class="error__message_box"><span class="error-message">';
                  errorMessage += error;
                  errorMessage += '</span></div>';
                });
                errorMessage += '</div>';
                formGroupElement.append(errorMessage);
              }
            } else {
              formGroupElement.removeClass('has-error');
            }
          });
        });
      }
    };
  })
    .directive('formGroup', function () {
      return {
        restrict: 'C',
        priority: 1000,
        link: function (scope, element) {
          if (element.find('*:required').length || element.find('*[required=required]').length) {
            element.addClass('required');
          }
        }
      };
    });

  function config(RestangularProvider, OAUTH_CONFIG, PARAMS, $locationProvider, datepickerPopupConfig, toastrConfig, $modalProvider, $logProvider) {
    RestangularProvider.setBaseUrl(OAUTH_CONFIG.baseUrl + '/api');
    datepickerPopupConfig.datepickerPopup = 'dd/MM/yyyy';
    datepickerPopupConfig.pickTime = false;

    $logProvider.debugEnabled(false);

    if (angular.isDefined(PARAMS)) {
      if (angular.isDefined(PARAMS.debug)) {
        $logProvider.debugEnabled(PARAMS.debug);
      }
    }
    // Extend toast
    angular.extend(toastrConfig, {
      allowHtml: true
    });

    // add a custom response interceptor to deal with non-array (i.e. json object) return values for getList() calls
    // to the backend
    RestangularProvider.addResponseInterceptor(function (data, operation) {
      var extractedData;
      // .. to look for getList operations
      if (operation === 'getList') {
        // .. and handle the data and meta data
        extractedData = data.results;
        extractedData.error = data.error;
        extractedData.count = data.count;
      } else {
        extractedData = data;
      }
      return extractedData;
    });
    $modalProvider.options = {
      backdrop: 'static',
      keyboard: false
    };
    $locationProvider.html5Mode(true);
  }
}());
